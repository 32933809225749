import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Api } from 'src/app/configs/config';
import { AxActivityDto } from 'src/app/shared/models/axActivityDto';
import { AxActivityProgrammeDto } from 'src/app/shared/models/axActivityProgrammeDto';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor(
    private http: HttpClient
  ) { }

	getActivties(): Observable<AxActivityDto[]>{
    return this.http.get<AxActivityDto[]>(Api.getActivitiesUrl);
  }

  getActivtyProgrammes(): Observable<AxActivityProgrammeDto[]>{
    return this.http.get<AxActivityProgrammeDto[]>(Api.getActivityProgrammesUrl);
  }

}
