<div class="container-fluid container-fluid--sePadding pb-3">
	<!-- Page Heading -->
	<app-page-heading
		iconName="cloud_upload"
		title="Upload Data File"
		subTitle="Upload data extract for processing in Excel format.">
	</app-page-heading>

	<!-- Upload Section -->
	<div class="upload-section">
		<div class="upload-section__header">FILE TO UPLOAD</div>
		<div class="d-flex w-100 p-6 mb-3 align-items-center justify-content-center flex-wrap" *ngIf="isSport()">
			<p class="me-0 me-md-3">As a sports customer, you will need to enter a <b>year</b> this data is for</p>
			<mat-form-field appearance="fill">
				<mat-label>Data year</mat-label>
				<mat-select [(ngModel)]="year" required>
					<mat-option *ngFor="let year of years" [value]="year">
						{{ year }}
					</mat-option>
				</mat-select>
				<mat-hint>Season / Playing Year etc.</mat-hint>
			</mat-form-field>
		</div>
		<div class="upload-section__buttons">
			<app-icon-button
				*ngIf="!upLoading && (!isSport() || year)"
				(buttonClicked)="file.click()"
				[icon]="'cloud_upload'"
				[text]="'SELECT FILE'"
				[size]="'large'"
				[color]="'green'"></app-icon-button>
			<app-icon-button
				*ngIf="upLoading"
				(buttonClicked)="cancelUpload()"
				[icon]="'cloud_off'"
				[text]="'CANCEL'"
				[size]="'large'"
				[color]="'grey'"></app-icon-button>
			<app-icon-button
				*ngIf="!upLoading && isSport() && !year"
				[icon]="'cloud_upload'"
				[text]="'SELECT FILE'"
				[size]="'large'"
				[color]="'grey'"></app-icon-button>

			<span class="upload-section__buttons--label"
				>{{ fileName || 'No file selected' }}
				<b *ngIf="!fileName && isSport() && !year">(you will need to select a year)</b></span
			>

			<div class="upload-section__buttons--download">
				<a
					href="/assets/files/ActiveXchange Data Specification.pdf"
					download="ActiveXchange Data Specification.pdf"
					target="_blank">
					<mat-icon class="material-icons-outlined">picture_as_pdf</mat-icon>
					<span>Download Data Specification</span>
				</a>
			</div>
		</div>
	</div>

	<!-- Warning if previous file upload has issues -->
	<app-error-panel
		[routerLink]="['/job-errors/' + dataIssues.jobId]"
		padding="high"
		*ngIf="dataIssues?.totalIssues > 0"
		iconName="report"
		[inputText]="
			'There are ' +
			dataIssues.totalIssues +
			' unresolved issues with the last file you uploaded.  Click to view details.'
		">
	</app-error-panel>

	<!-- Custom File Input -->
	<div>
		<div class="custom-file">
			<input #file type="file" id="customFile" accept=".xls, .xlsx" (change)="upload(file.files)" />
		</div>
	</div>
	<div *ngIf="upLoading" class="progress">
		<div class="progress-bar" role="progressbar" [style.width.%]="progress"></div>
	</div>

	<!-- Job History -->
	<div class="history">
		<div class="history__header">PREVIOUS FILE UPLOADS</div>
		<div class="history__content mb-6">
			<app-job-history [organisationId]="organisationId"></app-job-history>
		</div>
	</div>
</div>
