<header>
	<h2 mat-dialog-title>{{isNew ? 'New' : 'Edit existing'}} Usage</h2>
</header>

<content>
	<form class="container" [formGroup]="usageForm">
		<div class="row">
			<div class="col-12 col-md-5">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Activity Type</mat-label>
					<mat-select formControlName="axActivityProgramme">
						<mat-option *ngFor="let activity of activities" [value]="activity.id">{{activity.name}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-12 col-md-5">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Activity Programme</mat-label>
					<mat-select formControlName="axActivityProgrammeId">
						<ng-container *ngIf="filteredProgrammes">
							<mat-option *ngFor="let programme of filteredProgrammes" [value]="programme.id">{{programme.name}}</mat-option>
						</ng-container>
						<ng-container *ngIf="!filteredProgrammes">
							<mat-option *ngFor="let programme of allProgrammes" [value]="programme.id">{{programme.name}}</mat-option>
						</ng-container>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-12 col-md-2">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Quantity</mat-label>
					<input matInput formControlName="quantity" type="number">
					<mat-error>Please enter quantity</mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-4">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Competition Compliance</mat-label>
					<mat-select formControlName="competitionComplianceInd">
					  <mat-option  *ngFor="let compliance of competitionCompliances" [value]="compliance.id">{{compliance.name}}</mat-option>
					</mat-select>
					<mat-error>Please select competition compliance</mat-error>
				</mat-form-field>
			</div>
			<div class="col-12 col-md-4">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Usage Type</mat-label>
					<mat-select formControlName="nidFacilityUsageTypeInd">
					  <mat-option  *ngFor="let usageType of faciltyUsageType" [value]="usageType.id">{{usageType.name}}</mat-option>
					</mat-select>
					<mat-error>Please select usage type</mat-error>
				</mat-form-field>
			</div>
			<div class="col-12 col-md-4">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Hire Agreement Type</mat-label>
					<mat-select formControlName="hireAgreementInd">
					  <mat-option  *ngFor="let agreement of hireAgreements" [value]="agreement.id">{{agreement.name}}</mat-option>
					</mat-select>
					<mat-error>Please select Hire Agreement type</mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<mat-form-field class="w-100" appearance="fill">
					<mat-label>Any other Usages?</mat-label>
					<input matInput formControlName="otherUsage" >
					<mat-error>Please enter other usages</mat-error>
					</mat-form-field>
			</div>
		</div>
	</form>
</content>

<footer>
	<app-icon-button [icon]="'cancel'" [text]="'CANCEL'" [size]="'small'" [color]="'grey'" (click)="cancel()"></app-icon-button>
	<app-icon-button [icon]="'check_circle'" [text]="'SAVE'" [size]="'small'" (click)="submit()"></app-icon-button>
</footer>
