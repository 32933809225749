export class AppRoutes{
    public static readonly Home: string = '/';
    public static readonly Maps: string = '/maps';
    public static readonly Organisation: string = '/admin/organisations/{organisationId}';
    public static readonly Organisations: string = '/admin/organisations';
    public static readonly Dashboards: string = '/dashboardsv2';
    public static readonly Retention: string = '/';
    public static readonly Academy: string = '/academy';
    public static readonly Reports: string = '/reports';
}
