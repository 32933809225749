<header>
	<h2>{{ title }}</h2>
</header>

<content class="confirmationMessage">
	<span [innerHTML]="message"></span>
</content>

<footer>
	<app-icon-button
		icon="cancel"
		text="CANCEL"
		size="small"
		(buttonClicked)="onDismiss()"
		color="grey"
		type="button"></app-icon-button>
	<app-icon-button
		icon="check_circle"
		[text]="acceptButtonLabel"
		size="small"
		(buttonClicked)="onConfirm()"
		[color]="acceptButtonColour"></app-icon-button>
</footer>
