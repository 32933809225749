import { Component, Input, OnInit , ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent implements OnInit {

	@Input() iconName: string = '';
	@Input() iconOutlined: boolean = true;
	@Input() notificationText: string = '';
	@Input() callToActionText: string = '';
	@Input() compact: boolean = false;

	constructor() { }

	ngOnInit(): void {
  	}
}
