import { Component, Input, OnInit } from '@angular/core';
import { FacilityService } from 'src/app/core/services/facility.service';
import { Facility } from 'src/app/shared/models/facility';
import { MessageService } from 'src/app/core/services/message.service';
import { ConfirmationComponent, ConfirmDialogModel } from 'src/app/shared/components/confirmation/confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { NIDFacilityUsageEditComponent, NIDFacilityUsageEditComponentArgs } from '../../edit/facility-usage-edit/facility-usage-edit.component';
import { NIDSiteModalState } from '../../../nid-modal.component';
import { FacilityUsage } from 'src/app/shared/models/facilityUsage';

@Component({
	selector: 'app-facility-view',
	templateUrl: './facility-view.component.html',
	styleUrls: ['./facility-view.component.scss']
})
export class NIDFacilityViewComponent implements OnInit {
	@Input() siteData: NIDSiteModalState;

	facilities: Facility[] = [];
	currentFacility: Facility = { id: 0 };
	facilityUsages = [];

	loading: boolean = true;


	constructor(
		private facilityService: FacilityService,
		private messageService: MessageService,
		public dialog: MatDialog
	) { }


	ngOnInit(): void {
		this.loadSiteFacilities();
	}


	loadSiteFacilities() {
		console.warn("Load Site Facilities Invoked");
		this.facilityService.getFacilitiesForSite(this.siteData.siteId).subscribe(
			(data: Facility[]) => {

				if (data) {
					this.loading = false;
					this.facilities = data;

					if (this.facilities.length > 0) {
						if(this.siteData.selectedFacilityId > 0){
							const selectedFac = this.facilities.find(x => x.id == this.siteData.selectedFacilityId);
							this.showFacility(selectedFac);
						}else{
							this.showFacility(this.facilities[0])
						}
					}
				}
			}, err => this.handleError(err)
		);
	}

	/** Switch shown Facility */
	showFacility(facility: Facility) {
		this.currentFacility = facility;
		this.siteData.selectedFacilityId = facility.id;
		this.getUsages(false);
	}


	/** Add New Facility Click Handler */
	addNew(): void {
		//check if user have the permission of edit the site
		if(!this.siteData.userCanEditSite){
			this.messageService.showError("Please contact the Site Owner/Operator or ActiveXchange Support to request changes.");
			return;
		}

		this.siteData.selectedFacilityId = 0;
		this.siteData.editMode = true;
	}

	handleError(err: any) {
		this.loading = false;
	}


	/** Remove Facility Click Handler */
	removeFacility(facility: Facility): void {
		if (!this.siteData.userCanEditSite) {
			this.messageService.showError("Please contact the Site Owner/Operator or ActiveXchange Support to request changes.");
			return;
		}

		const message = `Are you sure you want to do delete this facilty? (${facility.spaceTypeIndStr})`;
		const dialogData = new ConfirmDialogModel('Confirm Delete', message, 'DELETE');

		const dialogRef = this.dialog.open(ConfirmationComponent, {
			maxWidth: '400px',
			data: dialogData,
			panelClass: 'seDialog',
			disableClose: true
		});

		dialogRef.afterClosed().subscribe(dialogResult => {
			if (!dialogResult) { return; }
			this.facilityService.deleteFacility(facility.id).subscribe(
				() => {
					this.messageService.showSuccess('Facility has been deleted');
					this.siteData.selectedFacilityId = 0;
					this.loadSiteFacilities();
				}, err => this.handleError(err)
			);
		});
	}

	checkFlag(flag: number, v: number): boolean {
		return (flag & v) > 0;
	}

	getStatusColor(v: number) {
		if (v == 0)
			return '#11944F';
		else
			return '#0086b3';

	}


	/**
	 * Get the usages for the currently selected facility
	 * @param useDelay Introduce a small delay while awaiting DB writes on the server
	 */
	getUsages(useDelay: boolean) {
		const delay = useDelay ? 250 : 0;
		setTimeout(() => {
			this.facilityService.getFacilityUsages(this.siteData.selectedFacilityId).subscribe(
				data => {
					this.facilityUsages = data;
				}
			);
		}, delay);
	}


	/** Remove Usage Click Handler */
	removeUsage(usage: FacilityUsage): void {
		if(!this.siteData.userCanEditSite){
			this.messageService.showError("Please contact the Site Owner/Operator or ActiveXchange Support to request changes.");
			return;
		}
		const dialogData = new ConfirmDialogModel('Confirm Delete', "Are you sure you want to delete '" + usage.axActivityProgramme.name + " '?", 'DELETE');

		const dialogRef = this.dialog.open(ConfirmationComponent, {
			maxWidth: '400px',
			data: dialogData,
			panelClass: 'seDialog',
			disableClose: true
		});

		dialogRef.afterClosed().subscribe(dialogResult => {
			if (!dialogResult) { return; }
			this.facilityService.deleteUsage(usage.id).subscribe(
				() => {
					this.messageService.showSuccess('Usage has been deleted');
					this.getUsages(true);
				}
			);
		});
	}


	/** Exisiting Facility Usage Click Handler */
	editUsage(usageId): void {
		if(!this.siteData.userCanEditSite){
			this.messageService.showError("Please contact the Site Owner/Operator or ActiveXchange Support to request changes.");
			return;
		}
		const dialogData: NIDFacilityUsageEditComponentArgs = {
			facilityId: this.currentFacility.id,
			usageId: usageId
		};

		this.openUsageDialog(dialogData);
	}


	/** New Facility Usage Click Handler */
	newUsage(): void {
		if(!this.siteData.userCanEditSite){
			this.messageService.showError("Please contact the Site Owner/Operator or ActiveXchange Support to request changes.");
			return;
		}
		const dialogData: NIDFacilityUsageEditComponentArgs = {
			facilityId: this.currentFacility.id
		};

		this.openUsageDialog(dialogData);
	}


	/** Open Facility Usage Dialog */
	openUsageDialog(args: NIDFacilityUsageEditComponentArgs){
		const dialogRef = this.dialog.open(NIDFacilityUsageEditComponent,  {
			disableClose: true,
			data: args,
			maxWidth: "900px",
			panelClass: "seDialog"
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result === true)
				this.getUsages(true);
		});
	}
}
