import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  template: '',
})
export default class BaseComponent implements OnDestroy {
  protected subscriptionComplete: Subject<boolean> = new Subject();
  loading: boolean = false;

  ngOnDestroy(): void {
    this.subscriptionComplete.next(true);
    this.subscriptionComplete.complete();
  }
}
