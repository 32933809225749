import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { KeyValuePair } from 'src/app/shared/models/keyvaluepair';
import { EnumService } from 'src/app/core/services/enum.service';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { SiteService } from 'src/app/core/services/site.service';
import { Site } from 'src/app/shared/models/site';
import { MapService } from 'src/app/core/services/maps/map.service';
import { MessageService } from 'src/app/core/services/message.service';
import { NIDRecordEditCloseEvent, NIDSiteModalState } from '../../../nid-modal.component';


@Component({
	selector: 'app-site-edit',
	templateUrl: 'site-edit.component.html',
	styleUrls: ['site-edit.component.scss']
})
export class NIDSiteEditComponent implements OnInit {

	@Input() siteData: NIDSiteModalState;
	@Output() editRecordCloseEvent = new EventEmitter<NIDRecordEditCloseEvent>();

	loading: boolean = false;
	isNew: boolean = true;
	pageTitle: string = 'Add New Site';
	pageSubTitle: string = 'Create Site'
	serverErrors: string[] = [];
	timeMask: any = [/\d/, /\d/, ':', /\d/, /\d/];

	constructor(
		private formBuilder: UntypedFormBuilder,
		private enumService: EnumService,
		private siteService: SiteService,
		private messageService: MessageService,
		private mapService: MapService,
		private errHandlerService: ErrorHandlerService
	) { }



	form = this.formBuilder.group(
		{

			name: ['', Validators.required],
			statusInd: [0, Validators.required],
			ownerName: [''],
			ownerTypeInd: [0],
			ownerTypeOther: [null],
			contactEmail: [''],
			websiteURL: [''],

			operatorName: [''],
			operatorTypeInd: [0],
			operatorTypeOther: [null],

			approxBuildYear: [null, Validators.max(2099)],
			approxLastRefurbYear: [null, Validators.max(2099)],

			chargingPolicyInd: [0],
			chargingPolicyOther: [null],
			chargingPolicyPrice: [null],

			foodAndBeverageServiceInd: [0],
			transportFlag: [0],
			otherServicesFlag: [0],

			totalChangingRoooms: [null],
			totalParkingSpaces: [null],
			totalMeetingFunctionRooms: [null],

			sportServicesFlag: [0],
			conditionInd: [0],
			conditionRatedDateTimeUTC: [new Date()],
			comments: [''],

			address: ['', Validators.required],
			latitude: [null],
			longitude: [null],
			refSpatialDataId: [null],
			concurrencyStamp: [null],
			openingHoursWeekdayOpenStr: [''],
			openingHoursWeekdayCloseStr: [''],
			openingHoursWeekendOpenStr: [''],
			openingHoursWeekendCloseStr: [''],

			accessibilityInd: [0],
			accessibilityCertified: [false],
			coLocationFlag: [0],
			feeSubsidyFlag: [0],
			overallSiteSizeM2: 0
		}
	);

	accessibility: KeyValuePair[] = [];
	orgTypes: KeyValuePair[] = [];
	charginPolicies: KeyValuePair[] = [];
	foodAndBeverageServices: KeyValuePair[] = [];
	conditions: KeyValuePair[] = [];
	status: KeyValuePair[] = [];

	transportFlags: KeyValuePair[] = [];
	sportServicesFlags: KeyValuePair[] = [];
	otherServicesFlags: KeyValuePair[] = [];
	coLocatedWithFlags: KeyValuePair[] = [];
	feeSubsidyFlags: KeyValuePair[] = [];


	ngOnInit(): void {
		this.loadMasterData();

		if (this.siteData.siteId > 0) {
			this.isNew = false;
			this.loadSite(this.siteData.siteId);
		}
		else {
			console.log('NEW SITE: lat/lng ' + this.siteData.newSiteLatitude, this.siteData.newSiteLongitude)
			this.f.latitude.setValue(this.siteData.newSiteLatitude);
			this.f.longitude.setValue(this.siteData.newSiteLongitude);
		}
	}

	get f() {
		return this.form.controls;
	}

	setPageTitles(title: string, subTitle: string) {
		this.pageTitle = title;
		this.pageSubTitle = subTitle;
	}

	loadMasterData() {
		this.enumService.enums.subscribe((enums) => {
			this.orgTypes = enums.OrganisationType;
			this.charginPolicies = enums.ChargingPolicy;
			this.conditions = enums.Condition;
			this.foodAndBeverageServices = enums.FoodAndBeverageService;
			this.status = enums.Status;
			this.accessibility = enums.Accessibility;
			this.transportFlags = enums.TransportFlag.filter(x => x.id > 0);
			this.sportServicesFlags = enums.SportServicesFlag.filter(x => x.id > 0);
			this.otherServicesFlags = enums.OtherServicesFlag.filter(x => x.id > 0);
			this.coLocatedWithFlags = enums.CoLocationFlag.filter(x => x.id > 0);
			this.feeSubsidyFlags = enums.FeeSubsidyFlag.filter(x => x.id > 0);
		});
	}

	loadSite(sId: number) {
		this.siteService.getSite(sId).subscribe(
			data => {
				console.log(data);
				this.populateData(data);
			},
			err => { this.handleError(err) }
		);
	}

	//Populate Form controls from backend data
	populateData(data: Site) {

		this.setPageTitles('Edit ' + data.name, 'Edit a site');

		Object.keys(this.form.controls).forEach(key => {
			this.form.controls[key].setValue(data[key]);
			console.log(this.form.controls[key].value);
		});


	}

	//Set Concurrency Stamp
	setConcurrencyStamp() {

	}

	//Save or Update
	submitForm() {
		this.serverErrors = [];

		if (this.form.invalid) {
			console.log(this.form)
			return;
		}

		const site: Site = this.form.value;

		if (!this.siteData.siteId) {

			this.siteService.createSite(site).subscribe(
				newSiteId => {
					if (newSiteId) {

						this.editRecordCloseEvent.emit({
							recordId: newSiteId,
							recordWasUpdated: true
						});

						this.messageService.showSuccess('New Site has been created.');
					}
				}, err => this.handleError(err)
			);
		} else {
			site.id = this.siteData.siteId;
			this.siteService.updateSite(this.siteData.siteId, site).subscribe(
				data => {
					if (data) {
						this.messageService.showSuccess('Site has been updated.');

						this.editRecordCloseEvent.emit({
							recordId: this.siteData.siteId,
							recordWasUpdated: true
						});
					}
				},
				err => this.handleError(err)
			);
		}
	}


	handleError(err: any) {
		this.loading = false;
		this.serverErrors = this.errHandlerService.handleError(err);

	}


	/** Cancel Button Event Handler */
	cancel(v: any) {
		this.editRecordCloseEvent.emit({
			recordId: this.siteData.siteId,
			recordWasUpdated: false
		});
	}

	//
	onFlagChange(e: any, key: string, id: number) {
		//
		if (e.checked)
			this.form.controls[key].setValue(this.form.controls[key].value + id);
		else
			this.form.controls[key].setValue(this.form.controls[key].value - id);
		console.log(this.form.controls[key].value);
	}

	checkFlag(flag: number, v: number): boolean {
		return (flag & v) > 0;
	}

}
