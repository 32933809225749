import {
  Component,
  HostListener,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';

import BaseComponent from 'src/app/core/base/base-component.component';
import { AxTableFilterDialogComponent } from './ax-table-filter-dialog/ax-table-filter-dialog.component';

import { AxTableColumn } from './ax-table-column';
import { ColumnFilterDialogData } from './ax-table-filter-dialog/column-filter-dialog-data';
import { AxTableAction } from './ax-table-action';

@Component({
  selector: 'app-ax-table',
  templateUrl: './ax-table.component.html',
  styleUrl: './ax-table.component.scss',
})
export class AxTableComponent<T extends object> extends BaseComponent implements OnInit, OnChanges {
  @Input() displayedColumnsDesktopAdmin: Array<string> = [];
  @Input() displayedColumnsDesktop: Array<string> = [];
  @Input() displayedColumnsMobile: Array<string> = [];
  @Input() columns: Array<AxTableColumn<T>> = [];
  @Input() data: Array<T> = [];

  @Input() showNoDataMessage: boolean = false;
  @Input() showTypeAheadFilter: boolean = true;
  @Input() isAdmin: boolean = false;
  @Input() canSelectRow: boolean = true;

  @Output() rowClick = new EventEmitter<T>();

  displayedColumns: Array<string> = [];
  dataSource = new MatTableDataSource<T>();

  @ViewChild(MatSort) sort: MatSort;

  filterString: string = '';

  private originalData: Array<T>;
  private filteredData: Array<T>;

  readonly columnFilterDialog = inject(MatDialog);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setColumns();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data) {
      if (changes.data) {
        if (!this.originalData) {
          this.originalData = this.data;
        }

        this.dataSource.data = this.data;
        this.dataSource.sort = this.sort;
      }
    }
  }

  // Handle row click event
  onRowClick(rowData: T): void {
    this.rowClick.emit(rowData);
  }

  // Check to see if a filter function is configured for a column
  hasFilter(column: AxTableColumn<T>): boolean {
    return column.hasOwnProperty('columnFilterOptionsFn');
  }

  // Handle column filter button click
  onColumnFilterClick(column: AxTableColumn<T>): void {
    // open dialog and provide options
    this.openColumnFilterDialog(column);
  }

  // Open the dialog for column filter
  private openColumnFilterDialog(column: AxTableColumn<T>): void {
    const dialogRef = this.columnFilterDialog.open(AxTableFilterDialogComponent, {
      data: {
        header: column.header,
        cellDataType: column.cellDataType,
        options: column.columnFilterOptionsFn(column).sort((a, b) => this.compare(a, b, true)),
        selectedOptions: column.columnFilterSelection,
      } as ColumnFilterDialogData,
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.subscriptionComplete))
      .subscribe((result: Array<string>) => {
        if (result) {
          column.columnFilterSelection = result.length > 0 ? result : undefined;
          this.refreshData();
        }
      });
  }

  // Renew and filter the data based on column selections
  private refreshData(): void {
    this.filteredData = this.originalData.slice();
    for (const col of this.columns) {
      if (!col.columnFilterSelection || col.columnFilterSelection.length === 0) {
        continue;
      }

      // Loop through all columns and filter data based on column filter values
      this.filteredData = this.filteredData.filter((item: T) =>
        col.columnFilterSelection.includes(col.cellDataFn(item))
      );
    }
    this.dataSource.data = this.filteredData;
  }

  // Handle the filter text box changes
  onFilterChange(event: Event): void {
    this.filterString = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.filterString;
  }

  // Handle clear filter button
  onClearFiltersClick(): void {
    this.columns.forEach((col: AxTableColumn<T>) => delete col.columnFilterSelection);
    this.filterString = '';
    this.dataSource.filter = this.filterString;
    this.refreshData();
  }

  // Handle sort change
  onSortChange(sort: Sort): void {
    // Revert to original sort order if sort is disabled
    if (!sort.active || this.sort.direction === '') {
      this.refreshData();
      return;
    }

    // Sort data based on selected sort column
    this.dataSource.data = this.dataSource.data
      .slice()
      .sort((a, b) => this.compare(a[sort.active], b[sort.active], sort.direction === 'asc'));
  }

  // Compare function for sorting
  private compare(a: string | number, b: string | number, isAsc: boolean): number {
    // always return -1 if there is no value to keep nulls at one end of the sorted result
    if (!a) {
      return -1;
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  // Check to see if filters have been applied
  hasFilterApplied(): boolean {
    return (
      !!this.filterString ||
      this.columns.findIndex(
        (col: AxTableColumn<T>) => col.columnFilterSelection && col.columnFilterSelection.length > 0
      ) !== -1
    );
  }

  // Check a string value is a valid date
  isValidDateString(dateString: string): boolean {
    const date = new Date(dateString);
    return date instanceof Date;
  }

  // Get the style from the column
  getCellStyle(column: AxTableColumn<T>, rowData: T): string {
    if (column.hasOwnProperty('cellStyleFn')) {
      return column.cellStyleFn(rowData);
    }
    return '';
  }

  // Get the style for the icon prefix
  getPrefixStyle(column: AxTableColumn<T>, rowData: T): string {
    if (column.hasOwnProperty('prefixIconStyleFn')) {
      return column.prefixIconStyleFn(rowData);
    }
    return '';
  }

  // Get the action button's visibility (default is true)
  showAction(action: AxTableAction<T>, element: T): boolean {
    if (action.hasOwnProperty('showActionFn')) {
      return action.showActionFn(element);
    }
    return true;
  }

  // Switch to column display for mobile or desktop when window is resized
  @HostListener('window:resize')
  onResize(): void {
    this.setColumns();
  }

  // Checks screen size for mobile view
  private isMobile = (): boolean => window.innerWidth < 1024;

  // Sets the displayed columns based on screen size (defaults to desktop view if no mobile columns are specified)
  private setColumns = (): void => {
    if (this.isMobile()) {
      this.displayedColumns =
        this.displayedColumnsMobile && this.displayedColumnsMobile?.length > 0
          ? this.displayedColumnsMobile
          : this.displayedColumnsDesktop;
    } else {
      this.isAdmin
        ? (this.displayedColumns = this.displayedColumnsDesktopAdmin)
        : (this.displayedColumns = this.displayedColumnsDesktop);
    }
  };
}
