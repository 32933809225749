import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
import { LoginShellComponent } from './modules/home/login-shell/login-shell.component';

import { HomeComponent } from './modules/home/home/home.component';
import { LoginComponent } from './modules/home/login/login.component';
import { PasswordForgotComponent } from './modules/home/password-forgot/password-forgot.component';
import { PasswordResetComponent } from './modules/home/password-reset/password-reset.component';

const routes: Routes = [
  // Login Section
  {
    path: 'login',
    component: LoginShellComponent,
    children: [
      { path: '', component: LoginComponent },
      { path: 'password-forgot', component: PasswordForgotComponent },
      { path: 'password-reset/:id', component: PasswordResetComponent },
    ],
  },
  { path: '', component: HomeComponent, canActivate: [authGuard] },

  // SportsEye Top Level Modules (Lazy Loaded)
  {
    path: 'maps',
    loadChildren: () => import('./modules/maps/maps.module').then((m) => m.MapsModule),
  },
  {
    path: 'dashboardsv2',
    loadChildren: () => import('./modules/dashboardsv2/dashboardsv2.module').then((m) => m.DashboardsV2Module),
  },
  {
    path: 'academy',
    loadChildren: () => import('./modules/academy/academy.module').then((m) => m.AcademyModule),
  },
  {
    path: 'reports',
    loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
    canMatch: [authGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
    canMatch: [authGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
