<span *ngIf="loading" class="spinner-overlay"><mat-spinner></mat-spinner></span>

<div *ngIf="!loading" class="container-fluid container-fluid--sePadding">
	<!-- Page Heading -->
	<app-page-heading
		[iconName]="'description'"
		[title]="'Reports'"
		[subTitle]="'Access bespoke reports and models shared by your organisation or produced for you by ActiveXchange.'">
	</app-page-heading>

	<!-- Empty Reports Section -->
	<app-empty-report *ngIf="!loading && totalReports === 0"></app-empty-report>

	<!-- Report Section -->
	<section class="reportSection" *ngFor="let report of reports | groupBy : 'reportSection'">
		<div class="reportSection__heading">{{ report.key }}</div>

		<div class="reportContainer">
			<div class="reportLink" *ngFor="let item of report.value; let i = index" (click)="fetchReportById(item)">
				<div class="reportLink__icon">
					<ng-container *ngIf="item.reportTypeInd === 1">
						<img src="assets/images/reports/text.svg" width="80" alt="file icon" />
					</ng-container>
					<ng-container *ngIf="item.reportTypeInd === 0">
						<img [src]="setFileIcon(item.fileName)" width="80" alt="file icon" />
						<span *ngIf="!item.isLoading">{{ item.fileSizeKb.toLocaleString() }} KB</span>
						<span *ngIf="item.isLoading"><img src="assets/images/loading.gif" /></span>
					</ng-container>
				</div>
				<div class="reportLink__copy">
					<h2 class="reportLink__copy__title">{{ item.title }}</h2>
					<p class="reportLink__copy__description">{{ item.description }}</p>
					<p class="reportLink__copy__author">
						by <b>{{ item.author }}</b> on <b>{{ item.publishDate | date : 'fullDate' }}</b>
					</p>
				</div>
			</div>
		</div>
	</section>
</div>
