import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api } from '../../../configs/config';
import { Observable } from 'rxjs';
import { OrganisationReportDto } from 'src/app/shared/models/organisation/organisationReportDto';

@Injectable({
	providedIn: 'root',
})

export class ReportsService {
	constructor(
		private http: HttpClient
	) {}

	saveReport(formData: any) {
		return this.http.post<any>(Api.postReportUrl, formData);
	}

	getOrganisationReports(organisationId: number): Observable<OrganisationReportDto[]> {
		return this.http.get<any>(Api.getOrganisationReportsUrl.replace('{id}', organisationId.toString()));
	}

	getOrganisationReportsById(reportId: number): Observable<any> {
		return this.http.get<any>(Api.getOrganisationReportsByIdUrl.replace('{id}', reportId.toString()));
	}

	deleteOrganisationReport(reportId: number): Observable<any> {
		return this.http.delete<any>(Api.deleteOrganisationReportsUrl.replace('{id}', reportId.toString()));
	}

	fetchOrganisationReport(reportId: number): Observable<any> {
		return this.http.get<any>(Api.fetchOrganisationReportUrl.replace('{id}', reportId.toString()));
	}

}
