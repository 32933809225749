import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const sportseyeAdminGuard = () => {
	const authService = inject(AuthService);
	const router = inject(Router);
	if (authService.getUserRole() == 'SportsEye Admin') {
        return true;
	}
	else {
		return router.parseUrl('/');
	}
}
