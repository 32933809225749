import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Api } from '../../configs/config';
import { environment } from '../../../environments/environment';

import {
  OrganisationDto,
  OrganisationSiteGroupDto,
  UnmappedEntitySummaryDto,
  TeamDto,
  UnmappedMembershipTypeDto,
  OrganisationSiteDto,
  OrganisationMembershipTypeDto,
} from 'src/app/shared/models/organisation';
import { UserDto } from 'src/app/shared/models/userDto';
import { UnmappedActivityDto } from 'src/app/shared/models/organisation/unmappedActivityDto';
import {} from 'src/app/shared/models/organisation/organisationSiteDto';
import { OrganisationActivitiesDto } from 'src/app/shared/models/organisation/organisationActivitiesDto';
import { AxMembershipTypeDto } from 'src/app/shared/models/axMembershipTypeDto';
import { AxActivityProgrammeDto } from 'src/app/shared/models/axActivityProgrammeDto';

@Injectable({
  providedIn: 'root',
})
export class OrganisationService {
  constructor(private http: HttpClient) {}

  createOrganisation(org): Observable<any> {
    return this.http.post<OrganisationDto>(Api.postOrganisationUrl, org);
  }

  updateOrganisation(id: number, org): Observable<any> {
    return this.http.put<OrganisationDto>(Api.putOrganisationUrl.replace('{id}', id.toString()), org);
  }

  getOrganisation(id: number): Observable<OrganisationDto> {
    return this.http.get<OrganisationDto>(Api.getOrganisationUrl.replace('{id}', id.toString()));
  }

  getOrganisations(): Observable<Array<OrganisationDto>> {
    return this.http.get<Array<OrganisationDto>>(Api.getOrganisationsUrl);
  }

  getOrganisationUsers(organisationId: number): Observable<Array<UserDto>> {
    return this.http.get<Array<UserDto>>(Api.getOrganisationUsersUrl.replace('{id}', organisationId.toString()));
  }

  getOrganisationSites(organisationId: number): Observable<Array<OrganisationSiteDto>> {
    return this.http.get<Array<OrganisationSiteDto>>(
      Api.getOrganisationSitesUrl.replace('{id}', organisationId.toString())
    );
  }

  getOrganisationSiteGroups(organisationId: number): Observable<OrganisationSiteGroupDto[]> {
    return this.http.get<any>(Api.getOrganisationSiteGroupsUrl.replace('{id}', organisationId.toString()));
  }

  getOrganisationSiteGroupSites(organisationId: number, siteGroupId: number): Observable<any> {
    return this.http.get<any>(
      Api.getOrganisationSiteGroupSitesUrl
        .replace('{orgId}', organisationId.toString())
        .replace('{id}', siteGroupId.toString())
    );
  }

  getOrganisationMembershipTypes(organisationId: number): Observable<Array<OrganisationMembershipTypeDto>> {
    return this.http.get<Array<OrganisationMembershipTypeDto>>(
      Api.getOrganisationMembershipTypesUrl.replace('{id}', organisationId.toString())
    );
  }

  getOrganisationActivities(organisationId: number): Observable<Array<OrganisationActivitiesDto>> {
    return this.http.get<Array<OrganisationActivitiesDto>>(
      Api.getOrganisationActivitiesUrl.replace('{id}', organisationId.toString())
    );
  }

  getOrganisationTeams(organisationId: number): Observable<Array<TeamDto>> {
    return this.http.get<Array<TeamDto>>(Api.getOrganisationTeamsUrl.replace('{id}', organisationId.toString()));
  }

  getOrganisationTeam(teamId: number): Observable<TeamDto> {
    return this.http.get<TeamDto>(Api.getOrganisationTeamUrl.replace('{teamId}', teamId.toString()));
  }

  getAxActivities(): Observable<any> {
    return this.http.get<any>(Api.getAxActivitiesUrl);
  }

  getAxActivityProgrammes(): Observable<Array<AxActivityProgrammeDto>> {
    return this.http.get<Array<AxActivityProgrammeDto>>(Api.getAxActivityProgrammesUrl);
  }

  getAxMembershipTypes(): Observable<Array<AxMembershipTypeDto>> {
    return this.http.get<Array<AxMembershipTypeDto>>(Api.getAxMembershipTypesUrl);
  }

  getAxMembershipTypesForOrganisation(organisationId: number): Observable<Array<AxMembershipTypeDto>> {
    return this.http.get<Array<AxMembershipTypeDto>>(
      Api.getAxMembershipTypesForOrganisationUrl.replace('{organisationId}', organisationId.toString())
    );
  }

  postOrgMembershipTypes(organisationId: number, lst: UnmappedMembershipTypeDto[]): Observable<any> {
    return this.http.post<UnmappedMembershipTypeDto[]>(
      Api.postRangeOrgMembershipTypesUrl.replace('{organisationId}', organisationId.toString()),
      lst
    );
  }

  postOrgActivities(organisationId: number, lst: UnmappedActivityDto[]): Observable<any> {
    return this.http.post<UnmappedActivityDto[]>(
      Api.postRangeOrgActivitiesUrl.replace('{organisationId}', organisationId.toString()),
      lst
    );
  }

  updateActivity(activity: OrganisationActivitiesDto): Observable<any> {
    console.log(activity);
    const url = `${environment.baseSEApiUrl}/api/v1/OrganisationActivities/${activity.id}`;
    return this.http.put(url, activity);
  }

  updateMembership(membership: { id: number; organisationId: number; axMembershipTypeId: number }): Observable<any> {
    const url = `${environment.baseSEApiUrl}/api/v1/OrganisationMembershipTypes/${membership.id}`;
    return this.http.put(url, membership);
  }

  postSiteGroupSites(orgSiteGroupSite: OrganisationSiteGroupDto): Observable<any> {
    const url = `${environment.baseSEApiUrl}/api/v1/OrganisationSiteGroup/siteandsitegroup`;
    return this.http.post<OrganisationSiteGroupDto>(url, orgSiteGroupSite);
  }

  putOrganisationDatayear(formData): Observable<any> {
    return this.http.put(Api.putOrganisationSaveDatayear, formData);
  }

  updateSite(site: OrganisationSiteDto): Observable<any> {
    const url = `${environment.baseSEApiUrl}/api/v1/OrganisationSites/${site.id}`;
    return this.http.put(url, site);
  }

  getGeographicLevelIds(country, level): Observable<any> {
    const url = `${environment.baseSEApiUrl}/api/v1/geographiclevels/${country}/${level}`;
    return this.http.get<any>(url);
  }

  getSportsProDatsetYear(organisationId: number): Observable<any> {
    return this.http.get<any>(Api.getSportsProDatsetYearsUrl.replace('{id}', organisationId.toString()));
  }

  getGeographicScopeTypes(country) {
    const url = `${environment.baseSEApiUrl}/api/v1/geographiclevels/${country}?scopeOnly=true`;
    return this.http.get<any>(url);
  }

  /** Given a Platform Identifier - determine if this is a Pro Account (platformInd ends with a 1) */
  isPlatformProAccount(platformInd: number): boolean {
    return platformInd % 10 == 1;
  }

  /** Return a summary of the total number of unmapped entities for a given organisation */
  getUnmappedEntitySummary(organisationId: number): Observable<UnmappedEntitySummaryDto> {
    return this.http.get<any>(Api.getUnmappedEntitySummaryUrl.replace('{organisationId}', organisationId.toString()));
  }

  postTeam(team: TeamDto): Observable<any> {
    return this.http.post(Api.postOrganisationTeamUrl, team);
  }

  deleteTeam(teamId: number): Observable<any> {
    return this.http.delete(Api.deleteOrganisationTeamUrl.replace('{teamId}', teamId.toString()));
  }

  getNIDConflicts(organisationId: number, jobId: number): Observable<any> {
    return this.http.get(
      Api.getOrganisationNidConflicts.replace('{id}', organisationId.toString()).replace('{jobId}', jobId.toString())
    );
  }

  postNIDConflictResolution(organisationId: number, jobId: number, data: number[]): Observable<any> {
    return this.http.post(
      Api.getOrganisationNidConflicts.replace('{id}', organisationId.toString()).replace('{jobId}', jobId.toString()),
      data
    );
  }
}
