<div class="container-fluid container-fluid--sePadding">
	<app-page-heading
		[iconName]="'report_problem'"
		[title]="'Job Errors'"
		[subTitle]="dataSource.data?.length + ' error(s) in upload jobs'">
		<app-icon-button [icon]="'arrow_back'" [text]="'BACK'" [size]="'large'" (buttonClicked)="back()" [color]="'grey'">
		</app-icon-button>
	</app-page-heading>

	<table mat-table [dataSource]="dataSource" class="ax-table">
		<ng-container matColumnDef="sheetName">
			<th mat-header-cell *matHeaderCellDef>SHEET NAME</th>
			<td mat-cell *matCellDef="let element">{{ element.sheetName }}</td>
		</ng-container>

		<ng-container matColumnDef="rowNumber">
			<th mat-header-cell *matHeaderCellDef>ROW NO</th>
			<td mat-cell *matCellDef="let element">{{ element.rowNumber }}</td>
		</ng-container>

		<ng-container matColumnDef="customerFacingErrorMessage">
			<th mat-header-cell *matHeaderCellDef>ERROR DESCRIPTION</th>
			<td mat-cell *matCellDef="let element">{{ element.customerFacingErrorMessage }}</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>
</div>
