import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { EtlService } from 'src/app/core/services/etl.service';
import { Job } from 'src/app/shared/models/job';
import { JobError } from 'src/app/shared/models/jobError';

@Component({
  selector: 'app-job-errors',
  templateUrl: './job-errors.component.html',
  styleUrls: ['./job-errors.component.scss']
})
export class JobErrorsComponent implements OnInit {
  loading: boolean = false;
  jobId: any = 0;
  job: Job;
  serverErrors: string[] = [];

  displayedColumns: string[] = [ 'sheetName', 'rowNumber', 'customerFacingErrorMessage'];
  dataSource =  new MatTableDataSource<JobError>();

  constructor(
    private route: ActivatedRoute,
    private etlService: EtlService,
    private errHandlerService: ErrorHandlerService,
		private location: Location
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      params=> {
        this.jobId = params.get('id');
        this.loadJobErrors(this.jobId);
      }
    );
  }

  loadJobErrors(jobId: number){
    this.etlService.getJobErrors(jobId).subscribe(
      data=>{
        this.job = data;
        this.dataSource.data = data.jobErrors;
        console.log(this.dataSource.data);
      }, err=> this.handleError(err)
    );
  }

  handleError(err: any) {
    this.loading = false;
    this.serverErrors =  this.errHandlerService.handleError(err);

  }

	back() {
		this.location.back()
	}


}
