import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'sportseye-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
	@Input() accent = true;
	@Input() isNew: boolean;
	@Input() date: Date;
	@Input() title: string;
	@Input() body: string;
	@Input() image: string;
	hovered: boolean;

	constructor() { }

	ngOnInit(): void {
	}

}
