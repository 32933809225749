import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { Api } from '../../configs/config';
import { Site } from 'src/app/shared/models/site';
import { NidFilter } from 'src/app/shared/models/Filter';


@Injectable({
  providedIn: 'root'
})
export class SiteService {

  constructor(private httpService: HttpClient) { }

  getSitesForMap(): Observable<any>{
      return this.httpService.get<any>(Api.getMapPanelUI_Url)
  }

  
  getSite(id: number): Observable<any>{
    return this.httpService.get<any>(Api.getSiteUrl.replace('{id}', id.toString()));
  }

  createSite(site: Site): Observable<any>{
    return this.httpService.post<Site>(Api.postSiteUrl,site);
  }

  updateSiteLocation(site: Site): Observable<any>{
    return this.httpService.post<Site>(Api.updateSiteUrl,site);
  }

  updateSite(id: number, site: Site): Observable<any>{
    return this.httpService.put<Site>(Api.putSiteUrl.replace('{id}',id.toString()),site);
  }

}
