import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { EnumService } from 'src/app/core/services/enum.service';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { FacilityService, NidFacilitySpaceTypeMetadataDto } from 'src/app/core/services/facility.service';
import { MapService } from 'src/app/core/services/maps/map.service';
import { MessageService } from 'src/app/core/services/message.service';
import { Facility, nidFacilityMetadataForSpaceTypeDto } from 'src/app/shared/models/facility';
import { KeyValuePair } from 'src/app/shared/models/keyvaluepair';
import { NIDRecordEditCloseEvent, NIDSiteModalState } from '../../../nid-modal.component';

@Component({
	selector: 'app-facility-edit',
	templateUrl: 'facility-edit.component.html',
	styleUrls: ['facility-edit.component.scss']
})
export class NIDFacilityEditComponent implements OnInit {

	@Input() siteData: NIDSiteModalState;
	@Output() editRecordCloseEvent = new EventEmitter<NIDRecordEditCloseEvent>();

	inputTypeText: string = '';
	loading: boolean = false;
	isNew: boolean = true;
	pageTitle: string = 'Add New Facility';
	pageSubTitle: string = 'Create Facility'
	serverErrors: string[] = [];

	constructor(
		private formBuilder: UntypedFormBuilder,
		private enumService: EnumService,
		private facilityService: FacilityService,
		private messageService: MessageService,
		private mapService: MapService,
		private errHandlerService: ErrorHandlerService
	) { }
	mtd = new UntypedFormArray([]);
	form = this.formBuilder.group(
		{
			id: [0],
			name: ['', Validators.required],
			statusInd: [0, Validators.required],
			primaryBuiltConditionInd: [0],

			settingTypeInd: [0],
			spaceTypeInd: [0],
			surfaceTypeInd: [0],

			approxBuildYear: [null, [Validators.min(1899), Validators.max(2099)]],
			approxLastRefurbYear: [null, [Validators.min(1899), Validators.max(2099)]],
			remainingLifetime: [null],
			usageLevelInd: [0],

			totalDedicatedChangingRooms: [null],
			totalSpectatorSeatingCapacity: [null],
			spectatorSeatingConditionInd: [0],
			hasFloodLighting: [false],
			lightingLuxLevel: [null],

			usageMonthFlag: [4095],

			averageHireCost: [null],
			comments: [''],

			facilityType: [''],
			nidFacilitySubTypeId: [1],
			nidSiteId: [0, Validators.required],
			concurrencyStamp: ['71D1FC34-8D18-4774-8612-0E4482959FD2'],

			//inputType: [1]
		});


	facilitySpaceTypeMetadata: NidFacilitySpaceTypeMetadataDto[];
	facilitySpaceTypeMetadataForSelectedSpaceType: NidFacilitySpaceTypeMetadataDto[];

	conditions: KeyValuePair[] = [];
	status: KeyValuePair[] = [];
	usageMonthFlags: KeyValuePair[] = [];
	usageLevels: KeyValuePair[] = [];
	facilitySettings: KeyValuePair[] = [];
	facilitySpaces: KeyValuePair[] = [];
	facilitySurfaces: KeyValuePair[] = [];

	inputTypesAll: KeyValuePair[];
	inputTypes: KeyValuePair[];

	ngOnInit(): void {
		this.mtd.push(new UntypedFormControl(''));
		this.loadMasterData(this.siteData.selectedFacilityId);
	}

	get f() {
		return this.form.controls;
	}

	setPageTitles(title: string, subTitle: string) {
		this.pageTitle = title;
		this.pageSubTitle = subTitle;
	}

	updateSpaceTypeMetadata(selectedSpaceTypeInd: number){
		this.facilitySpaceTypeMetadataForSelectedSpaceType = this.facilitySpaceTypeMetadata.filter(x => x.spaceTypeInd == selectedSpaceTypeInd);
	}

	setMetadataNumericAnswer(question: NidFacilitySpaceTypeMetadataDto, event: any){
		const newValue = parseFloat(event.value);
		if(isNaN(newValue)){
			question.decimalAnswer = null;
		}else{
			question.decimalAnswer = newValue;
		}
	}

	setMetadataDropDownAnswer(question: NidFacilitySpaceTypeMetadataDto, value: number){
		question.dropDownAnswer = value;
	}

	getLabelForMetadataDropdown(question: NidFacilitySpaceTypeMetadataDto): string {
		if(question.dropDownAnswer){
			const selectedDropDownAnswer = question.dropDownOptions.filter(x => x.id == question.dropDownAnswer);
			return selectedDropDownAnswer[0].optionText;
		}

		return null;
	}

	shouldDisplayHelpSymbolForDropDown(question: NidFacilitySpaceTypeMetadataDto): boolean {
		let helpText = "";

		if(question.typeInd == 2 && question.dropDownAnswer){
			helpText = this.getHelpTextForMetadataDropdown(question);
		}

		return helpText.length > 0;
	}

	getHelpTextForMetadataDropdown(question: NidFacilitySpaceTypeMetadataDto): string {
		if(question.dropDownAnswer){
			const selectedDropDownAnswer = question.dropDownOptions.filter(x => x.id == question.dropDownAnswer)[0];
			return selectedDropDownAnswer.longDescription.length > 0 ? selectedDropDownAnswer.longDescription : "";
		}

		return "";
	}

	loadFacility(id: number) {

		this.facilityService.getFacility(id).subscribe(
			data => {

				this.isNew = false;
				this.setPageTitles('Edit ' + data.name, 'Edit a Facility');

				data.nidFacilityMetadataForSpaceType?.forEach(answer => {
					console.error("Mapping " + answer.nidFacilitySpaceTypeMetadataId);
					let currentQuestion = this.facilitySpaceTypeMetadata.find(x => x.id == answer.nidFacilitySpaceTypeMetadataId);
					if(currentQuestion){
						switch(answer.typeInd){
							case 0:
								currentQuestion.textAnswer = answer.textAnswer;
								break;

							case 1:
								currentQuestion.decimalAnswer = answer.decimalAnswer;
								break;

							case 2:
								currentQuestion.dropDownAnswer = answer.dropDownAnswerId;
								break;
						}
					}
				});

				console.error("All Questions");
				console.error(this.facilitySpaceTypeMetadata);

				this.updateSpaceTypeMetadata(data.spaceTypeInd);

				console.error("Displayed Questions");
				console.error(this.facilitySpaceTypeMetadataForSelectedSpaceType);

				// let sType = this.facilitySubTypesAll.filter(x => x.id == data['nidFacilitySubTypeId'])
				// let fType = this.facilityTypes.filter(x => x.id == sType[0].nidFacilityTypeId)
				// console.log(fType[0].id);

				// this.facilitySubTypes = this.facilitySubTypesAll.filter(x => x.nidFacilityTypeId == fType[0].id);

				Object.keys(this.form.controls).forEach(key => {
					this.form.controls[key].setValue(data[key]);
					console.log(this.form.controls[key].value);
				});

				// this.form.controls['facilityType'].setValue(fType[0].id);

				// //-- Adding MetaData returned from backend
				// if (data.nidFacilityMetadatas && data.nidFacilityMetadatas.length > 0) {
				// 	data.nidFacilityMetadatas.forEach(md => {
				// 		let subTypeMetaData = this.facilitySubTypeMetaDataAll.find(x => x.id == md.nidFacilitySubTypeMetaDataId);
				// 		if (subTypeMetaData) {
				// 			if (md.decimalAnswer && md.decimalAnswer != 0)
				// 				subTypeMetaData.value = md.decimalAnswer.toString();
				// 			else
				// 				subTypeMetaData.value = md.textAnswer.toString();
				// 			subTypeMetaData.conditionInd = md.conditionInd;
				// 		}
				// 	});
				// }
			},
			err => { this.handleError(err) }
		);
	}

	//Populate Form controls from backend data
	// populateData(data: Facility) {
	// 	this.form.controls.name.setValue(data.name);
	// }

	handleError(err: any) {
		this.loading = false;
		this.serverErrors = this.errHandlerService.handleError(err);
	}

	/** Load Master Data */
	loadMasterData(facilityId) {
		this.loading = true;

		// -- retrieve required enums
		this.enumService.enums.subscribe((enums) => {
			this.conditions = enums.Condition;
			this.status = enums.Status;
			this.usageMonthFlags = enums.UsageMonthFlag.filter(x => x.id > 0);
			this.usageLevels = enums.UsageLevel;
			this.inputTypes = enums.InputType;
			this.facilitySettings = enums.NidFacilitySettingInd;
			this.facilitySpaces = enums.NidFacilitySpaceTypeInd;
			this.facilitySurfaces = enums.NidFacilitySurfaceTypeInd;
		});


		// -- load facility space type metadata
		this.facilityService.getFacilitySpaceTypeMetadata().subscribe(data => {

			this.facilitySpaceTypeMetadata = data;
			this.loading = false;

			// if loading an existing facility
			if (this.siteData.selectedFacilityId > 0)
				this.loadFacility(this.siteData.selectedFacilityId);
			else
				this.updateSpaceTypeMetadata(0);
		});
	}


	//Save or Update
	submitForm() {

		this.form.markAllAsTouched();

		this.form.controls.nidSiteId.setValue(this.siteData.siteId);
		this.serverErrors = [];
		if (this.form.invalid){
			console.error(this.form.errors);
			return;
		}

		const facility: Facility = this.form.value;

		// -- assign new metadata
		facility.nidFacilityMetadataForSpaceType = [];
		this.facilitySpaceTypeMetadataForSelectedSpaceType.forEach(x => {
			let newMetaData: nidFacilityMetadataForSpaceTypeDto = {
				id: x.id,
				decimalAnswer: x.decimalAnswer,
				dropDownAnswerId: x.dropDownAnswer,
				nidFacilitySpaceTypeMetadataId: x.id,
				textAnswer: x.textAnswer,
				typeInd: x.typeInd
			};

			if(newMetaData.decimalAnswer || newMetaData.dropDownAnswerId || newMetaData.textAnswer?.length > 0)
				facility.nidFacilityMetadataForSpaceType.push(newMetaData);
		});

		// -- Saving Facility with Metadata
		if (!this.siteData.selectedFacilityId) {

			this.facilityService.createFacility(facility).subscribe(
				newRecordId => {
					if (newRecordId) {
						this.messageService.showSuccess('New Facility has been created.');
						this.editRecordCloseEvent.emit({
							recordId: newRecordId,
							recordWasUpdated: true
						});
					}

				}, err => this.handleError(err)
			);
		} else {
			facility.id = this.siteData.selectedFacilityId;
			this.facilityService.updateFacility(this.siteData.selectedFacilityId, facility).subscribe(
				data => {
					if (data) {
						this.messageService.showSuccess('Facility has been updated.');
						this.editRecordCloseEvent.emit({
							recordId: this.siteData.selectedFacilityId,
							recordWasUpdated: true
						});
					}
				},
				err => this.handleError(err)
			);
		}
	}


	/** Cancel Button Event Handler */
	cancel(v: any) {
		this.editRecordCloseEvent.emit({
			recordId: this.siteData.selectedFacilityId,
			recordWasUpdated: false
		});
	}

	//
	onFlagChange(e: any, key: string, id: number) {
		//
		if (id == 0) {
			this.form.controls[key].setValue(4095);
		} else if (id == -1) {
			this.form.controls[key].setValue(0);
		} else {
			if (e.checked)
				this.form.controls[key].setValue(this.form.controls[key].value + id);
			else
				this.form.controls[key].setValue(this.form.controls[key].value - id);
		}

		console.log(this.form.controls[key].value);
	}

	checkFlag(flag: number, v: number): boolean {
		return (flag & v) > 0;
	}

	onFacilityTypeChange(e: any) {
		//this.facilitySubTypes = this.facilitySubTypesAll.filter(x => x.nidFacilityTypeId == e.value);
		// this.facilitySubTypeMetaDataAll.forEach(x=> {
		//   if (x.id == this.f.)
		// })
	}
}
