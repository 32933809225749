import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'app-icon-button',
	templateUrl: './icon-button.component.html',
	styleUrls: ['./icon-button.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class IconButtonComponent {
	@Input() text: string = '';
	@Input() icon: string = '';
	@Input() size: string = '';
	@Input() color: string = 'green';
	@Input() type: string = 'button';
	@Input() showProgress: boolean = false;
	@Input() disabled: boolean = false;

	@Output() buttonClicked: EventEmitter<string> = new EventEmitter<string>();

	constructor() {}

	/** Custom Class to added to button */
	bemModifierClasses(): string {
		let result: string = '';

		if (this.size.length > 0) result += ' seBtn--' + this.size;
		if (this.color.length > 0) result += ' seBtn--' + this.color;

		return result;
	}

	btnClicked() {
		this.buttonClicked.emit('Button Clicked');
	}
}
