<app-loading-indicator
	*ngIf="loading"
	text="Loading Site Information">
</app-loading-indicator>

<section *ngIf="!loading && this.siteData.siteId > 0" class="nid-modal__tab-panel">

	<span [ngStyle]="{'background-color': getStatusColor(site.statusInd)}" class="status-ind">{{site.statusIndStr}}</span>

	<!-- Site Details -->
	<section class="info-box">
		<div class="info-box__header">
			<mat-icon class="material-icons-outlined">description</mat-icon>
			<span>Site Details</span>
		</div>
		<div class="info-box__content">
			<div class="info-box__table-wrapper">
				<dl *ngFor="let site of siteDetailsList | keyvalue: originalOrder">
					<dt>{{site.key}}</dt>
					<dd>{{site.value}}</dd>
				</dl>
			</div>
		</div>
	</section>

	<!-- Location -->
	<section class="info-box">
		<div class="info-box__headerwrapper">
			<div class="info-box__header">
				<mat-icon class="material-icons-outlined">public</mat-icon>
				<span>Location</span>
			</div>
			<div class="info-box__icons" *ngIf="siteData.showGeographicControls">
				<mat-icon matTooltip="Edit the location of this site" matTooltipPosition="left" (click)="editLocation()">pin_drop</mat-icon>
				<mat-icon matTooltip="Edit Quadkey Collection" matTooltipPosition="left" (click)="editQuadkey()">grid_view</mat-icon>
			</div>
		</div>
		<div class="info-box__content">
			<div class="info-box__table-wrapper">
				<dl *ngFor="let location of locationList | keyvalue: originalOrder; let last = last">
					<dt>{{location.key}}</dt>
					<dd>{{location.value}}</dd>
				</dl>
			</div>
		</div>
	</section>

	<!-- Icon Boxes -->
	<section class="icon-boxes">
		<!-- Site Access -->
		<div class="info-box info-box--site-access">
			<div class="info-box__header">
				<mat-icon class="material-icons-outlined">swap_horiz</mat-icon>
				<span>Site Access (&lt;100m)</span>
			</div>
			<div class="info-box__content">
				<div class="info-box__icon-wrapper">
					<div *ngIf="site.transportFlag == 0" class="access-icons access-icons__empty-state">
						<mat-icon class="material-icons-outlined">rule</mat-icon>
						<span>None Selected</span>
					</div>
					<div *ngIf="checkFlag(site.transportFlag,1)" class="access-icons">
						<mat-icon class="material-icons-outlined">directions_car</mat-icon>
						<span>Car</span>
					</div>
					<div *ngIf="checkFlag(site.transportFlag,2)" class="access-icons">
						<mat-icon class="material-icons-outlined">directions_bike</mat-icon>
						<span>Bike</span>
					</div>
					<div *ngIf="checkFlag(site.transportFlag,4)" class="access-icons">
						<mat-icon>add_road</mat-icon>
						<span>Bike Path</span>
					</div>
					<div *ngIf="checkFlag(site.transportFlag,8)" class="access-icons">
						<mat-icon class="material-icons-outlined">directions_bus</mat-icon>
						<span>Bus Stop</span>
					</div>
					<div *ngIf="checkFlag(site.transportFlag,16)" class="access-icons">
						<mat-icon class="material-icons-outlined">train</mat-icon>
						<span>Train Stop</span>
					</div>
					<div *ngIf="checkFlag(site.transportFlag,32)" class="access-icons">
						<mat-icon class="material-icons-outlined">tram</mat-icon>
						<span>Tram Stop</span>
					</div>
					<div *ngIf="checkFlag(site.transportFlag,64)" class="access-icons">
						<mat-icon class="material-icons-outlined">directions_walk</mat-icon>
						<span>Footpath</span>
					</div>
				</div>
			</div>
		</div>

		<!-- Sports Services -->
		<div class="info-box info-box--sport-services">
			<div class="info-box__header">
				<mat-icon class="material-icons-outlined">sports_rugby</mat-icon>
				<span>Sports Services</span>
			</div>

			<div class="info-box__content">
				<div class="info-box__icon-wrapper">
					<div *ngIf="site.sportServicesFlag == 0" class="access-icons access-icons__empty-state">
						<mat-icon class="material-icons-outlined">rule</mat-icon>
						<span>None Selected</span>
					</div>
					<div *ngIf="checkFlag(site.sportServicesFlag,1)" class="access-icons">
						<mat-icon class="material-icons-outlined">meeting_room</mat-icon>
						<span>Official Rooms</span>
					</div>
					<div *ngIf="checkFlag(site.sportServicesFlag,2)" class="access-icons">
						<mat-icon class="material-icons-outlined">local_hospital</mat-icon>
						<span>Medical Rooms</span>
					</div>
				</div>
			</div>
		</div>

		<!-- Services -->
		<div class="info-box info-box--services">
			<div class="info-box__header">
				<mat-icon class="material-icons-outlined">miscellaneous_services</mat-icon>
				<span>Services</span>
			</div>
			<div class="info-box__content">
				<div class="info-box__icon-wrapper">
					<div *ngIf="site.otherServicesFlag == 0" class="access-icons access-icons__empty-state">
						<mat-icon class="material-icons-outlined">rule</mat-icon>
						<span>None Selected</span>
					</div>
					<div *ngIf="checkFlag(site.otherServicesFlag,1)" class="access-icons">
						<mat-icon class="material-icons-outlined">local_drink</mat-icon>
						<span>Water</span>
					</div>
					<div *ngIf="checkFlag(site.otherServicesFlag,2)" class="access-icons">
						<mat-icon class="material-icons-outlined">wc</mat-icon>
						<span>Toilets</span>
					</div>
					<div *ngIf="checkFlag(site.otherServicesFlag,4)" class="access-icons">
						<mat-icon class="material-icons-outlined">shopping_bag</mat-icon>
						<span>Retail</span>
					</div>
					<div *ngIf="checkFlag(site.otherServicesFlag,8)" class="access-icons">
						<mat-icon class="material-icons-outlined">keyboard</mat-icon>
						<span>Admin</span>
					</div>
					<div *ngIf="checkFlag(site.otherServicesFlag,16)" class="access-icons">
						<mat-icon class="material-icons-outlined">toys</mat-icon>
						<span>Play Area</span>
					</div>
					<div *ngIf="checkFlag(site.otherServicesFlag,32)" class="access-icons">
						<mat-icon class="material-icons-outlined">baby_changing_station</mat-icon>
						<span>Creche</span>
					</div>
					<div *ngIf="checkFlag(site.otherServicesFlag,64)" class="access-icons">
						<mat-icon class="material-icons-outlined">child_care</mat-icon>
						<span>Soft Play</span>
					</div>
					<div *ngIf="checkFlag(site.otherServicesFlag,128)" class="access-icons">
						<mat-icon class="material-icons-outlined">hot_tub</mat-icon>
						<span>Hot Tub</span>
					</div>
					<div *ngIf="checkFlag(site.otherServicesFlag,256)" class="access-icons">
						<mat-icon class="material-icons-outlined">local_drink</mat-icon>
						<span>Coaching</span>
					</div>
					<div *ngIf="checkFlag(site.otherServicesFlag,512)" class="access-icons">
						<mat-icon class="material-icons-outlined">accessible</mat-icon>
						<span>Disability</span>
					</div>
					<div *ngIf="checkFlag(site.otherServicesFlag,2048)" class="access-icons">
						<mat-icon class="material-icons-outlined">medical_services</mat-icon>
						<span>Health</span>
					</div>
					<div *ngIf="checkFlag(site.otherServicesFlag,4096)" class="access-icons">
						<mat-icon class="material-icons-outlined">local_parking</mat-icon>
						<span>Free Parking</span>
					</div>
				</div>

				<div class="info-box__table-wrapper info-box__table-wrapper--top-border info-box__table-wrapper--condensed">
					<dl *ngFor="let service of servicesList | keyvalue: originalOrder">
						<dt>{{service.key}}</dt>
						<dd>{{service.value}}</dd>
					</dl>
				</div>
			</div>
		</div>

	</section>
</section>
