import { Injectable } from '@angular/core';
import {
	MatSnackBar,
	MatSnackBarConfig,
	MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Observable, Subject } from 'rxjs';
import { UserDto } from 'src/app/shared/models/userDto';

@Injectable({
	providedIn: 'root',
})
export class MessageService {
	messages: string[] = [];
	showSeconds: number = 3000;
	verticalPosition: MatSnackBarVerticalPosition = 'bottom';
	public notification$: Observable<String> = new Observable<String>();

	public sideNavToggle$: Subject<boolean> = new Subject<boolean>();
	public closeNavToggle$: Subject<boolean> = new Subject<boolean>();
	public isLoggedIn$: Subject<boolean> = new Subject<boolean>();

	public UserInfo$: Subject<UserDto> = new Subject<UserDto>();

	constructor(private snackBar: MatSnackBar) {}

	toggleSidnav() {
		let opened: boolean = false;

		this.sideNavToggle$.next(opened);
	}

	closeSidenav() {
		let closed: boolean = false;

		this.closeNavToggle$.next(closed);
	}

	isLoggedIn(v: boolean) {
		this.isLoggedIn$.next(v);
	}

	setUserInfo(usrInfo: UserDto) {
		this.UserInfo$.next(usrInfo);
	}

	showInfo(message: string) {
		this.showSnackBar(message, 'Info', 'snackbar-info-message');
	}

	showError(message: string) {
		this.showSnackBar(message, 'Error', 'snackbar-error-message');
	}

	showSuccess(message: string) {
		this.showSnackBar(message, 'Success', 'snackbar-success-message');
	}

	showWarning(message: string) {
		this.showSnackBar(message, 'Warning', 'snackbar-warning-message');
	}

	private showSnackBar(message: string, action: string, messageCssClass: string) {
		const config = new MatSnackBarConfig();
		config.panelClass = [messageCssClass];
		config.duration = this.showSeconds;
		config.verticalPosition = this.verticalPosition;

		this.snackBar.open(message, action, config);
	}
}
