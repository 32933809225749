import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-empty-report',
	templateUrl: './empty-report.component.html',	
    styleUrls: ['./empty-report.component.scss'],
})
export class EmptyReportComponent implements OnInit {

    constructor(){}

    ngOnInit(): void {
		
	}

}