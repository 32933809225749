<app-header *ngIf="isLoggedIn"></app-header>

<mat-sidenav-container class="sidenav-container p-0 m-0" [ngClass]="{ 'global-mt': isLoggedIn }">
  <mat-sidenav #sidenav mode="over" [(opened)]="opened">
    <mat-nav-list class="ps-2">
      <mat-list-item *ngFor="let menu of menuItems" (click)="toggleSideNav()" [routerLink]="menu.routeKey">
        <app-icon-text [iconName]="menu.icon" [inputText]="menu.text | titlecase"></app-icon-text>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content class="m-0">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
