import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivityService } from 'src/app/core/services/activity.service';
import { EnumService } from 'src/app/core/services/enum.service';
import { FacilityService } from 'src/app/core/services/facility.service';
import { MessageService } from 'src/app/core/services/message.service';
import { AxActivityDto } from 'src/app/shared/models/axActivityDto';
import { AxActivityProgrammeDto } from 'src/app/shared/models/axActivityProgrammeDto';
import { FacilityUsage } from 'src/app/shared/models/facilityUsage';
import { KeyValuePair } from 'src/app/shared/models/keyvaluepair';

@Component({
	selector: 'app-facility-usage-edit',
	templateUrl: 'facility-usage-edit.component.html',
	styleUrls: ['facility-usage-edit.component.scss']
})
export class NIDFacilityUsageEditComponent implements OnInit {
	isNew: boolean;

	activities: AxActivityDto[];
	allProgrammes: AxActivityProgrammeDto[];
	filteredProgrammes: AxActivityProgrammeDto[];
	competitionCompliances: KeyValuePair[];
	hireAgreements: KeyValuePair[];
	faciltyUsageType: KeyValuePair[];

	usageForm = this.fb.group({
		axActivityProgramme: [0],
		axActivityProgrammeId: [0],
		quantity: [1],
		competitionComplianceInd: [0],
		hireAgreementInd: [0],
		otherUsage: [''],
		nidFacilityUsageTypeInd: [0]
	});

	constructor(
		public dialogRef: MatDialogRef<NIDFacilityUsageEditComponent>,
		@Inject(MAT_DIALOG_DATA) public data: NIDFacilityUsageEditComponentArgs,
		private fb: UntypedFormBuilder,
		private facilityService: FacilityService,
		private activityService: ActivityService,
		private enumService: EnumService,
		private messageService: MessageService
	) { }

	async ngOnInit() {
		this.isNew = this.data.usageId ? (this.data.usageId > 0 ? false : true) : true;
		this.activities = await this.activityService.getActivties().toPromise();
		this.allProgrammes = await this.activityService.getActivtyProgrammes().toPromise();

		console.warn("Facility Usage: ngOnInit()");
		console.warn(this.data);
		console.warn(this.isNew);

		this.getRequiredEnums();
		this.initialiseForm();
		this.handleChanges();
	}

	getRequiredEnums(){
		this.enumService.enums.subscribe(enums => {
			 this.hireAgreements = enums.HireAgreement;
			 this.competitionCompliances = enums.CompetitionCompliance;
			 this.faciltyUsageType = enums.NidFacilityUsageTypeInd;
		});
	}

	async initialiseForm() {
		if (this.isNew) {
			this.usageForm.get('axActivityProgramme').setValue(this.activities[0].id);
			this.usageForm.get('axActivityProgrammeId').setValue(this.allProgrammes[0].id);
		} else {
			const usage = await this.facilityService.getUsage(this.data.usageId).toPromise();
			Object.keys(this.usageForm.controls).forEach(control =>
				this.usageForm.get(control).setValue(usage[control])
			);
			this.usageForm.get('axActivityProgramme').setValue(this.allProgrammes.find(programme =>
				programme.id === usage['axActivityProgrammeId']).axActivityId);
		}
	}

	handleChanges(): void {
		this.usageForm.get('axActivityProgramme').valueChanges.subscribe(change => {
			this.filteredProgrammes = this.allProgrammes.filter(programme => programme.axActivityId === change);
			if (this.isNew) {
				this.usageForm.get('axActivityProgrammeId').setValue(this.filteredProgrammes[0].id);
			}
		});
	}

	async getActivities() {
		this.activities = await this.activityService.getActivties().toPromise();
	}

	async getProgrammes() {
		this.allProgrammes = await this.activityService.getActivtyProgrammes().toPromise();
	}

	submit(): void {
		const id = this.data.usageId ? this.data.usageId : 0;

		let qty = this.usageForm.get('quantity').value;
		if(qty < 1){
			this.messageService.showError("Quantity of a usage must be 1 or higher");
			return;
		}

		const usage: FacilityUsage = {
			id,
			quantity: this.usageForm.get('quantity').value,
			otherUsage: this.usageForm.get('otherUsage').value,
			competitionComplianceInd: this.usageForm.get('competitionComplianceInd').value,
			hireAgreementInd: this.usageForm.get('hireAgreementInd').value,
			axActivityProgrammeId: this.usageForm.get('axActivityProgrammeId').value,
			nidFacilityUsageTypeInd: this.usageForm.get('nidFacilityUsageTypeInd').value,
			nidFacilityId: this.data.facilityId
		};

		if (this.isNew) {
			this.facilityService.createUsage(usage).subscribe(() =>
				this.messageService.showSuccess('New facility usage has been created.'));
				this.dialogRef.close(true);
		} else {
			this.facilityService.updateUsage(this.data.usageId, usage).subscribe(() =>
				this.messageService.showSuccess('Facility usage has been updated.'));
				this.dialogRef.close(true);
		}

	}

	cancel(): void {
		this.dialogRef.close(false);
	}
}

/** Arguments passed in when opening the modal */
export interface NIDFacilityUsageEditComponentArgs
{
	facilityId: number;
	usageId?: number;
}
