import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Api } from 'src/app/configs/config';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { EtlService } from 'src/app/core/services/etl.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { MessageService } from 'src/app/core/services/message.service';
import { JobHistoryComponent } from 'src/app/shared/components/job-history/job-history.component';


@Component({
	selector: 'app-upload-file',
	templateUrl: './upload-file.component.html',
	styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent {
	organisationId: number;
	serverErrors: string[] = [];

	upLoading: boolean;
	fileName = '';
	progress: number;
	uploadSub: Subscription = null;

	dataIssues: any;

	maxYear = new Date().getFullYear() + 1;
	minYear = new Date().getFullYear() - 15;

	years = [...Array(this.maxYear - this.minYear + 1).keys()].map(i => i + this.minYear).reverse();

	platform;

	year = new Date().getFullYear();

	@ViewChild(JobHistoryComponent) jobHistoryComponent: JobHistoryComponent;

	constructor(
		private http: HttpClient,
		private etlService: EtlService,
		private authService: AuthService,
		private errHandlerService: ErrorHandlerService,
		private router: Router,
		private messageService: MessageService
	) {
		this.organisationId = this.authService.getUserOrganisationId();
		this.getDataIssueSummary(this.organisationId);
		this.platform = this.authService.getUserInfo().organisationPlatform;
		console.log('Platform:', this.platform);
	}

	/** Asynchronously upload the file */
	upload(files : FileList) {
		console.warn(files);

		if (!files || files.length === 0){
			this.messageService.showError("No file has been provided");
			return;
		}

		if(files.length > 1){
			this.messageService.showError("Only one file can be uploaded at a time");
			return;
		}

		if (!this.hasExtension(files[0].name, ['.xls', '.xlsx'])) {
			this.messageService.showError('Please choose a valid file format');
			return;
		}

		// -- create form data
		const formData = new FormData();
		formData.append(files[0].name, files[0]);
		this.fileName = files[0].name;


		this.upLoading = true;
		const url = this.year ? `${Api.etlFileUploadUrl}/${this.year}` : Api.etlFileUploadUrl;
		const uploadReq = new HttpRequest('POST', url, formData, {
			reportProgress: true,
		});

		this.uploadSub = this.http.request(uploadReq).subscribe(event => {
			console.log(event.type);
			if (event.type === HttpEventType.UploadProgress) {
				this.progress = Math.round(100 * event.loaded / event.total);
			};

			if (event.type === HttpEventType.Response) {
				this.reset();
				this.jobHistoryComponent.loadJobHistory(this.organisationId);
				this.messageService.showInfo('Your file upload was successful');
			}
		}, err => this.handleError(err));
	}


	/** Cancel a file upload that's in progress */
	cancelUpload() {
		this.reset();
		this.messageService.showInfo("Your upload has been cancelled");
	}


	/** Reset file upload progress */
	reset() {
		this.upLoading = false;
		this.progress = null;

		try{
			if(this.uploadSub != undefined){
				this.uploadSub.unsubscribe();
				this.uploadSub = undefined;
			}
		} catch(error) {
			console.warn("Exception trying to unsubscribe from file upload");
		}
	}


	/** Handle HTTP Errors */
	handleError(err: any) {
		this.reset();
		this.serverErrors = this.errHandlerService.handleError(err);
	}


	/** Validate that a filename has a particular file extension */
	hasExtension(fileName: string, validExtensions: string[]) {
		return (new RegExp('(' + validExtensions.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
	}


	/** Retrieve any data issues from the last uploaded job */
	getDataIssueSummary(OrgId: number) {
		//this.etlService.getDataIssueSummary(OrgId.toString()).subscribe(
		//	data => {
		//		this.dataIssues = data;
		//		console.log(this.dataIssues);
		//	}
		//);
	}

	isSport() {
		return this.platform.includes('Sport');
	}
}
