<div class="slider-form-field-container">
	<mat-slider
		class="slider"
		[color]="color"
		[disableRipple]="disableRipple"
		[discrete]="discrete"
		[displayWith]="displayWith"
		[max]="max"
		[min]="min"
		[showTickMarks]="showTickMarks"
		[step]="step">
		<ng-container *ngIf="endControl.value !== null; else singleInput">
			<input
				matSliderStartThumb
				[formControl]="startControl"
				(focusin)="onFocusIn()"
				(focusout)="onFocusOut($event)"
				(dragStart)="handleDragStart()"
				(dragEnd)="handleDragEnd()" />
			<input
				matSliderEndThumb
				[formControl]="endControl"
				(focusin)="onFocusIn()"
				(focusout)="onFocusOut($event)"
				(dragStart)="handleDragStart()"
				(dragEnd)="handleDragEnd()" />
		</ng-container>
		<ng-template #singleInput>
			<input
				matSliderThumb
				[formControl]="startControl"
				(focusin)="onFocusIn()"
				(focusout)="onFocusOut($event)"
				(dragStart)="handleDragStart()"
				(dragEnd)="handleDragEnd()" />
		</ng-template>
	</mat-slider>
</div>
