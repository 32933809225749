import { NgModule } from '@angular/core';

import { SharedModule } from 'src/app/shared/shared.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { JobErrorsComponent } from './job-errors/job-errors.component';


import { UploadFileComponent } from './upload-file/upload-file.component';
import { DataIssuesComponent } from './data-issues/data-issues.component';




@NgModule({
  declarations: [
    UploadFileComponent,
    JobErrorsComponent,
    DataIssuesComponent,
],
  imports: [
    SharedModule,
    AppRoutingModule,

  ]
})
export class EtlModule {

 }
