<p *ngIf="(!data || !data.length) && showNoDataMessage">There is no data to display.</p>

<ng-container *ngIf="data && data.length">
  <div *ngIf="showTypeAheadFilter">
    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Type to filter</mat-label>
      <input matInputtype="text" matInput [(ngModel)]="filterString" (input)="onFilterChange($event)" />
    </mat-form-field>

    <button
      type="button"
      class="seBtn seBtn--green"
      aria-label="clear filters button"
      (click)="onClearFiltersClick()"
      [disabled]="!hasFilterApplied()">
      <mat-icon fontIcon="filter_alt"></mat-icon>
      <span class="seBtn__text">Clear all Filters</span>
    </button>
  </div>

  <table
    mat-table
    *ngIf="data && data.length"
    [dataSource]="dataSource"
    matSort
    (matSortChange)="onSortChange($event)"
    class="ax-table">
    <ng-container *ngFor="let col of columns" matColumnDef="{{ col.columnDef }}">
      <ng-container *ngIf="col.cellDataType !== 'actions'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [class]="col.headerAlignment ?? ''">
            {{ col.header }}
            <button *ngIf="hasFilter(col)" (click)="onColumnFilterClick(col); $event.stopPropagation()">
              <mat-icon fontIcon="filter_alt"></mat-icon>
            </button>
        </th>
      </ng-container>
      <ng-container *ngIf="col.cellDataType === 'actions'">
        <th mat-header-cell *matHeaderCellDef [class]="col.headerAlignment ?? ''">
          {{ col.header }}
          <button *ngIf="hasFilter(col)" (click)="onColumnFilterClick(col); $event.stopPropagation()">
            <mat-icon fontIcon="filter_alt"></mat-icon>
          </button>
        </th>
      </ng-container>

      <ng-container *ngIf="col.cellDataType === 'string'">
        <td
          mat-cell
          *matCellDef="let rowData"
          [class]="col.wrapContent ? 'wrap ' : '' + col.cellAlignment"
          [style]="getCellStyle(col, rowData)">
          <mat-icon *ngIf="col.prefixIconName && col.cellDataFn(rowData)" [style]="getPrefixStyle(col, rowData)">
            {{ col.prefixIconName }}
          </mat-icon>
          <span>{{ col.cellDataFn(rowData) }}</span>
        </td>
      </ng-container>

      <ng-container *ngIf="col.cellDataType === 'html'">
        <td
          mat-cell
          *matCellDef="let rowData"
          [class]="col.wrapContent ? 'wrap ' : '' + col.cellAlignment"
          [style]="getCellStyle(col, rowData)"
          [innerHTML]="col.cellDataFn(rowData) | safeHtml"
          (click)="col.cellDataHtmlCallbackFn(rowData)"></td>
      </ng-container>

      <ng-container *ngIf="col.cellDataType === 'boolean'">
        <td
          mat-cell
          class="center"
          *matCellDef="let rowData"
          [class]="col.wrapContent ? 'wrap ' : '' + col.cellAlignment"
          [style]="getCellStyle(col, rowData)">
          <mat-icon *ngIf="col.cellDataFn(rowData) === 'true'" fontIcon="check_circle_outline"></mat-icon>
          <mat-icon *ngIf="col.cellDataFn(rowData) === 'false'" fontIcon="highlight_off"></mat-icon>
        </td>
      </ng-container>

      <ng-container *ngIf="col.cellDataType === 'date'">
        <td
          mat-cell
          *matCellDef="let rowData"
          [class]="col.wrapContent ? 'wrap ' : '' + col.cellAlignment"
          [style]="getCellStyle(col, rowData)">
          <!-- Some date fields require strings to be displayed instead of a date -->
          <ng-container *ngIf="isValidDateString(col.cellDataFn(rowData))">
            <ng-container *ngIf="!col.dateFormatString">
              {{ col.cellDataFn(rowData) | date }}
            </ng-container>
            <ng-container *ngIf="col.dateFormatString">
              {{ col.cellDataFn(rowData) | date : col.dateFormatString }}
            </ng-container>
          </ng-container>

          <ng-container *ngIf="!isValidDateString(col.cellDataFn(rowData))">
            {{ col.cellDataFn(rowData) }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container *ngIf="col.cellDataType === 'actions'">
        <td
          mat-cell
          *matCellDef="let rowData"
          [class]="col.wrapContent ? 'wrap ' : '' + col.cellAlignment"
          [style]="getCellStyle(col, rowData)">
          <ng-container *ngFor="let action of col.actions">
            <button
              *ngIf="showAction(action, rowData)"
              type="button"
              mat-icon-button
              [matTooltip]="action.name"
              (click)="action.actionFn(rowData)">
              <mat-icon>{{ action.iconName }}</mat-icon>
            </button>
          </ng-container>
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowClick(row)"></tr>
  </table>
</ng-container>
