import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MessageService } from 'src/app/core/services/message.service';
import { FileStatus } from '../../models/file-upload/file-status.enum';
import { SelectedFile } from '../../models/file-upload/selected-file.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogModel, ConfirmationComponent } from '../confirmation/confirmation.component';

@Component({
	selector: 'app-file-uploader',
	templateUrl: './file-uploader.component.html',
	styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnDestroy {
	@Input() acceptedTypes: string[] = [];
	@Input() multiSelect: boolean;
	@Input() uploadRequest$: Subject<Observable<HttpEvent<any>>> = new Subject();
	@Input() resetOnCompletion: boolean;

	@Output() uploadStarted = new EventEmitter<File>();
	@Output() fileUploaded = new EventEmitter();
	@Output() allFilesUploaded = new EventEmitter<boolean>();

	@ViewChild('fileInput') fileInput: HTMLInputElement;

	private unsubscribe$ = new Subject<void>();

	selectedFiles: SelectedFile[] = [];

	get FileStatus(): typeof FileStatus {
		return FileStatus;
	}

	get readyFiles(): SelectedFile[] {
		return this.selectedFiles.filter((file) => file.status === FileStatus.Added || file.status === FileStatus.Error);
	}

	get uploadingFiles(): SelectedFile[] {
		return this.selectedFiles.filter((file) => file.status === FileStatus.Next || file.status === FileStatus.Pending || file.status == FileStatus.Progress);
	}

	constructor(
		private messageService: MessageService,
		private dialog: MatDialog
		) {}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	startNextUpload(): void {
		if (this.selectedFiles.some((file) => file.status == FileStatus.Pending)) {
			const file = this.selectedFiles.find((file) => file.status == FileStatus.Pending);
			file.status = FileStatus.Progress;

			file.request = this.uploadRequest$
				.pipe(
					first(),
					switchMap((request: Observable<HttpEvent<any>>) => request),
					tap(() => this.startNextUpload()),
					takeUntil(this.unsubscribe$)
				)
				.subscribe(
					(event: HttpEvent<any>) => {
						if (event.type === HttpEventType.UploadProgress) {
							file.progress = Math.round((100 * event.loaded) / event.total);
						} else if (event.type === HttpEventType.Response) {
							file.progress = 100;
							file.status = FileStatus.Success;
							file.response = event;
							this.fileUploaded.emit();
							this.checkUploadStatus();
						}
					},
					(error: HttpErrorResponse) => {
						file.progress = 0;
						file.status = FileStatus.Error;
						file.response = error;
					}
				);
			this.uploadStarted.emit(file.file);
		}
	}

	selectFiles(files: FileList): void {
		this.fileInput.value = '';
		let fileFormatError = false;
		if (!files || files.length === 0) {
			this.messageService.showError('A valid file has not been provided');
			return;
		}
		if (!this.multiSelect && files.length > 1) {
			this.messageService.showError('Only one file can be selected at a time');
			return;
		}
		Array.from(files).forEach((file: File) => {
			if (!this.acceptedTypes || this.validateFileType(file.name)) {
				const selectedFile = new SelectedFile(file);
				this.selectedFiles.push(selectedFile);
				this.allFilesUploaded.emit(false);
			} else {
				fileFormatError = true;
			}
		});
		if (fileFormatError) {
			this.messageService.showError('Please choose a valid file format');
		}
	}

	removeFile(file: SelectedFile): void {
		this.selectedFiles.splice(this.selectedFiles.indexOf(file), 1);
		this.checkUploadStatus();
	}

	validateFileType(fileName: string): boolean {
		const acceptedTypes = this.acceptedTypes.join('|');
		return new RegExp(`\.(${acceptedTypes})$`, 'i').test(fileName);
	}

	upload(): void {
		this.readyFiles.forEach((file) => {
			file.status = FileStatus.Pending;
		});
		this.startNextUpload();
	}

	cancel(): void {
		const confirmDialogData = new ConfirmDialogModel('Stop Upload', 'Are you sure you want to stop uploading?', 'STOP');

		const confirmDialogRef = this.dialog.open(ConfirmationComponent, {
			maxWidth: '400px',
			data: confirmDialogData,
			panelClass: 'seDialog',
		});

		confirmDialogRef.afterClosed().subscribe((dialogResult) => {
			if (!dialogResult) {
				return;
			}
			this.uploadingFiles.forEach((file) => {
				if (file.request) {
					file.request.unsubscribe();
					file.progress = 0;
					file.status = FileStatus.Added;
				}
			});
		});
	}

	checkUploadStatus(): void {
		if (this.selectedFiles.every((file) => file.status == FileStatus.Success)) {
			this.allFilesUploaded.emit(true);
			if (this.resetOnCompletion) {
				this.selectedFiles = [];
			}
		}
	}
}
