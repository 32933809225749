<mat-card
	class="mat-elevation-z0 h-100"
	[ngClass]="{ 'hovered mat-elevation-z3': hovered }"
	(mouseover)="hovered = true"
	(mouseout)="hovered = false">
	<img mat-card-image src="{{ image }}" />
	<mat-card-content>
		<mat-card-title class="lh-1">{{ title }}</mat-card-title>
		<p>{{ body }}</p>
		<div class="date position-absolute bottom-0 end-0 me-3 text-white pt-1 px-2 text-center">
			<span>{{ date | date : 'MMM' }}</span>
			<h1 class="mb-1 lh-1">{{ date | date : 'd' }}</h1>
		</div>
	</mat-card-content>
</mat-card>
