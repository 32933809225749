import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// MATERIAL DESIGN IMPORTS
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';

// ANGULAR MATERIAL EXTENSIONS
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

// CUSTOM SHARED COMPONENTS
import { IconTextComponent } from './components/icon-text/icon-text.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { ErrorPanelComponent } from './components/error-panel/error-panel.component';
import { PageHeadingComponent } from './components/page-heading/page-heading.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { MatDialogModule } from '@angular/material/dialog';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NIDModalComponent } from './components/modals/nid-modal/nid-modal.component';
import { NIDSiteViewComponent } from './components/modals/nid-modal/components/view/site-view/site-view.component';
import { NIDFacilityViewComponent } from './components/modals/nid-modal/components/view/facility-view/facility-view.component';
import { NIDActionPlanViewComponent } from './components/modals/nid-modal/components/view/action-plan-view/action-plan-view.component';
import { NIDSiteEditComponent } from './components/modals/nid-modal/components/edit/site-edit/site-edit.component';
import { NIDFacilityEditComponent } from './components/modals/nid-modal/components/edit/facility-edit/facility-edit.component';
import { NIDActionPlanEditComponent } from './components/modals/nid-modal/components/edit/action-plan-edit/action-plan-edit.component';
import { NIDFacilityUsageEditComponent } from './components/modals/nid-modal/components/edit/facility-usage-edit/facility-usage-edit.component';
import { EditListComponent } from './components/edit-list/edit-list.component';
import { HelpModalComponent } from './components/modals/help-modal/help-modal.component';
import { AxTableComponent } from './components/ax-table/ax-table.component';
import { AxTableFilterDialogComponent } from './components/ax-table/ax-table-filter-dialog/ax-table-filter-dialog.component';

// PIPES
import { SafeUrlPipe } from './pipes/safeUrlPipe';
import { SafeHtmlPipe } from './pipes/safeHtmlPipe';
import { SafeStylePipe } from './pipes/safeStylePipe';
import { JobHistoryComponent } from './components/job-history/job-history.component';
import { CardDateComponent } from './components/cards/card-date/card-date.component';
import { CardHeroComponent } from './components/cards/card-hero/card-hero.component';
import { CardComponent } from './components/cards/card/card.component';
import { UploadFileModalComponent } from './components/modals/upload-file-modal/upload-file-modal.component';
import { DragAndDropDirective } from './directives/drag-and-drop/drag-and-drop.directive';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { DataYearComponent } from './components/data-year/data-year.component';
import { SliderFormFieldComponent } from './components/slider-form-field/slider-form-field.component';
import { MatPasswordStrengthComponent } from './components/mat-password-strength/component/mat-password-strength/mat-password-strength.component';
import { MatPasswordStrengthInfoComponent } from './components/mat-password-strength/component/mat-password-strength-info/mat-password-strength-info.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@NgModule({
  declarations: [
    AxTableComponent,
    AxTableFilterDialogComponent,
    IconTextComponent,
    IconButtonComponent,
    ErrorPanelComponent,
    PageHeadingComponent,
    ConfirmationComponent,
    LoadingIndicatorComponent,
    EmptyStateComponent,
    SafeUrlPipe,
    SafeHtmlPipe,
    SafeStylePipe,
    JobHistoryComponent,
    CardComponent,
    CardHeroComponent,
    CardDateComponent,
    EditListComponent,
    NIDModalComponent,
    NIDSiteViewComponent,
    NIDSiteEditComponent,
    NIDFacilityViewComponent,
    NIDFacilityEditComponent,
    NIDActionPlanViewComponent,
    NIDActionPlanEditComponent,
    NIDFacilityUsageEditComponent,
    HelpModalComponent,
    EditListComponent,
    UploadFileModalComponent,
    FileUploaderComponent,
    DragAndDropDirective,
    DataYearComponent,
    SliderFormFieldComponent,
    MatPasswordStrengthComponent,
    MatPasswordStrengthInfoComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatToolbarModule,
    MatSliderModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatListModule,
    MatMenuModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatCardModule,
    ReactiveFormsModule,
    FormsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    DragDropModule,
    MatPaginatorModule,
    NgxMatSelectSearchModule,
    MatRadioModule,
    MatTooltipModule,
    MatExpansionModule,
    MatRippleModule,
  ],
  exports: [
    CommonModule,
    AxTableComponent,
    AxTableFilterDialogComponent,
    CardComponent,
    CardHeroComponent,
    CardDateComponent,
    JobHistoryComponent,
    MatCardModule,
    MatToolbarModule,
    MatSliderModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatListModule,
    MatMenuModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatBadgeModule,
    DragDropModule,
    MatPaginatorModule,
    MatDialogModule,
    IconTextComponent,
    IconButtonComponent,
    ErrorPanelComponent,
    PageHeadingComponent,
    SafeUrlPipe,
    SafeHtmlPipe,
    SafeStylePipe,
    LoadingIndicatorComponent,
    EmptyStateComponent,
    NIDModalComponent,
    MatRadioModule,
    MatTooltipModule,
    MatExpansionModule,
    EditListComponent,
    FileUploaderComponent,
    DataYearComponent,
    SliderFormFieldComponent,
    MatRippleModule,
    MatPasswordStrengthComponent,
    MatPasswordStrengthInfoComponent,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-AU' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SharedModule {}
