import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
	selector: 'app-login-shell',
	templateUrl: './login-shell.component.html',
	styleUrls: ['./login-shell.component.scss']
})
export class LoginShellComponent implements OnInit {

	randomImagePath: string = "";

	constructor() { }

	ngOnInit(): void {
		let country = environment.instanceCountryISO3166;
		let backgroundNumber = Math.floor(Math.random() * 19);

		this.randomImagePath = "assets/images/backgrounds/" + country + "/" + backgroundNumber + ".jpg"
	}
}
