<form *ngIf="!isComplete" [formGroup]="form" (ngSubmit)="onSubmit()" class="loginShellForm">
	<h1>Forgotten password recovery</h1>
	<p>Simply enter your email address below and we'll send you instructions on how to reset your password.</p>

	<div class="mb-5">
		<label for="username">EMAIL ADDRESS :</label>
		<input type="email" id="username" class="form-control" formControlName="userName" />
	</div>

	<div class="mb-3 error-space negativeMT18">
		<app-error-panel
			*ngIf="serverErrors.length > 0"
			[iconName]="'error'"
			[inputText]="serverErrors[0]"></app-error-panel>
	</div>

	<div class="d-flex justify-content-between align-items-center">
		<app-icon-button
			[icon]="'check_circle'"
			[text]="'SUBMIT'"
			[size]="'large'"
			[type]="'submit'"
			[showProgress]="loading">
		</app-icon-button>

		<a [routerLink]="['/login']">Go back to login</a>
	</div>
</form>

<div *ngIf="isComplete">
	<h1>Forgotten password recovery</h1>
	<div class="d-flex align-items-center">
		<div style="flex: 1 1">
			<p>
				If your email address matched one of our accounts an email has been sent to you with instructions for resetting
				your password.
			</p>
			<p>Note the password reset link we have sent is only valid for 15 minutes.</p>
		</div>
		<div class="d-none d-md-block">
			<mat-icon class="bigIcon">check_circle</mat-icon>
		</div>
	</div>

	<app-icon-button [icon]="'arrow_back'" [text]="'BACK'" [size]="'large'" (buttonClicked)="navigate()" [color]="'grey'">
	</app-icon-button>
</div>
