import {
	MatDialogRef,
	MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
	selector: 'app-confirmation',
	templateUrl: './confirmation.component.html',
	styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent {
	title: string;
	message: string;
	acceptButtonLabel: string;
	acceptButtonColour: string = 'red';

	constructor(
		public dialogRef: MatDialogRef<ConfirmationComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
	) {
		// Update view with given values
		this.title = data.title;
		this.message = data.message;
		this.acceptButtonLabel = data.approved;
		this.acceptButtonColour = data.color;
	}

	onConfirm(): void {
		// Close the dialog, return true
		this.dialogRef.close(true);
	}

	onDismiss(): void {
		// Close the dialog, return false
		this.dialogRef.close(false);
	}
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {
	constructor(public title: string, public message: string, public approved: string, public color: string = 'red') {}
}
