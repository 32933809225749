<h2>Filter for: {{ data.header }}</h2>

<mat-dialog-content>
  <div>
    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Type to filter</mat-label>
      <input matInputtype="text" matInput [(ngModel)]="filterString" (input)="onFilterChange($event)" />
    </mat-form-field>
    <button mat-button (click)="onToggleSelectAll()">Select All</button>
  </div>

  <mat-selection-list #optionsList [(ngModel)]="model" (ngModelChange)="onSelectionChanged($event)">
    <ng-container *ngFor="let option of data.options">
      <mat-list-option
        *ngIf="['string', 'date', 'actions', 'html'].includes(data.cellDataType)"
        [value]="option"
        [selected]="isSelected(option)">
        {{ option }}
      </mat-list-option>
      <mat-list-option *ngIf="data.cellDataType === 'boolean'" [value]="option" [selected]="isSelected(option)">
        <mat-icon *ngIf="option === 'true'" fontIcon="check_circle_outline" class="green"></mat-icon>
        <mat-icon *ngIf="option === 'false'" fontIcon="highlight_off" class="red"></mat-icon>
      </mat-list-option>
    </ng-container>
  </mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="onOkClick()">Ok</button>
  <button mat-button (click)="onCancelClick()">Cancel</button>
</mat-dialog-actions>
