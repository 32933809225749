<div class="container-fluid container-fluid--sePadding">
	<app-page-heading
		[iconName]="'report_problem'"
		[title]="'Data Issues'"
		[subTitle]="tableData.length + ' issue(s) in the last file you uploaded'">
	</app-page-heading>
	<p class="not-found" *ngIf="!tableData.length">No issues found.</p>
	<table mat-table [dataSource]="tableData" class="mat-elevation-z0 ax-table" *ngIf="tableData.length">
		<ng-container matColumnDef="description">
			<th mat-header-cell *matHeaderCellDef>Description</th>
			<td mat-cell *matCellDef="let tableData">
				<p>{{ tableData.description }}</p>
			</td>
		</ng-container>

		<ng-container matColumnDef="value">
			<th mat-header-cell *matHeaderCellDef>No of Issues</th>
			<td mat-cell *matCellDef="let tableData">
				<a [routerLink]="[tableData.link, tableData.jobId]">{{ tableData.value }}</a>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>
</div>
