<!-- Password Reset Form-->
<form *ngIf="!isComplete" [formGroup]="form" (ngSubmit)="onSubmit()" class="loginShellForm">
	<h1>Reset your password</h1>
	<p>
		Password must be a minimum 8 characters and must also contain <br />
		a mixture of upper and lower case letters, digits and other characters.
	</p>

	<div class="mb-5">
		<label for="password">PASSWORD :</label>
		<input type="password" id="password" class="form-control" formControlName="password" />
	</div>

	<div class="mb-5">
		<label for="password">CONFIRM PASSWORD :</label>
		<input type="password" id="password" class="form-control" formControlName="confirmpassword" />
	</div>

	<div class="mb-3 error-space negativeMT18">
		<app-error-panel
			*ngIf="serverErrors.length > 0"
			[iconName]="'error'"
			[inputText]="serverErrors[0]"></app-error-panel>
	</div>

	<div class="d-flex justify-content-between">
		<app-icon-button
			[icon]="'check_circle'"
			[text]="'RESET'"
			[size]="'large'"
			[type]="'submit'"
			[showProgress]="loading">
		</app-icon-button>
	</div>
</form>

<!-- Password Reset Confirmation -->
<div *ngIf="isComplete">
	<h1>Reset your password</h1>
	<div class="d-flex align-items-center">
		<div style="flex: 1 1">
			<p>Your password has been reset.</p>
			<p>Please return to the login screen to login to ActiveXchange/p></p>
		</div>

		<div class="d-none d-md-block">
			<mat-icon class="bigIcon">check_circle</mat-icon>
		</div>
	</div>

	<app-icon-button [icon]="'arrow_back'" [text]="'BACK'" [size]="'large'" (buttonClicked)="navigate()" [color]="'grey'">
	</app-icon-button>
</div>
