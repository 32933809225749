import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { MessageService } from 'src/app/core/services/message.service';
import { ReportsService } from 'src/app/core/services/reports/reports.service';
import { saveAs } from 'file-saver';

@Component({
	selector: 'app-reports-list',
	templateUrl: './reports-list.component.html',
	styleUrls: ['./reports-list.component.scss'],
})
export class ReportsListComponent implements OnInit {
	loading: boolean = true;
	organisationId: any = 0;
	reports: any = [];
	totalReports: number = 0;

	constructor(
		private reportsService: ReportsService,
		private router: Router,
		private messageService: MessageService,
		private authService: AuthService
	) {}

	ngOnInit(): void {
		// get user organisation Id
		this.organisationId = this.authService.getUserInfo().organisationId;
		if (this.organisationId > 0)
			this.getOrganisationReports(this.organisationId);
	}

	getOrganisationReports(orgId: any) {
		this.reportsService.getOrganisationReports(orgId).subscribe(
			(data) => {
				this.loading = true;
				if (data) {
					this.reports = data;
					this.loading = false;
					this.totalReports = data.length;
				}
			},
			(err) => {
				this.handleError(err);
				this.loading = false;
			}
		);
	}

	handleError(err: any) {
		this.loading = false;
		if (err.status == 403) {
			this.router.navigate(['/login']);
		} else {
			console.log('Error' + err);
			this.messageService.showError('Something went wrong.');
		}
	}

	setFileIcon(val: string) {
		let fileExt = val.split('.').pop();
		if (['xls', 'xlsx'].includes(fileExt)) {
			return 'assets/images/reports/xls.svg';
		} else if (['doc', 'docx'].includes(fileExt)) {
			return 'assets/images/reports/doc.svg';
		} else if (['ppt', 'pptx'].includes(fileExt)) {
			return 'assets/images/reports/ppt.svg';
		} else if (['pdf'].includes(fileExt)) {
			return 'assets/images/reports/pdf.svg';
		}
	}

	fetchReportById(item: any) {
		console.warn("Loading", item);
		if(item.webUrl != ""){
			window.open(item.webUrl, '_blank');
		}else{
			item.isLoading = true;
			this.reportsService.fetchOrganisationReport(item.id).subscribe(
				data => {
					if (data) {
						this.loading = true;
						this.arraytoFile(data, item.fileName, item.mimeType);
						item.isLoading = false;
						this.loading = false;
					}
				}, err => {
					item.isLoading = false;
					this.handleError(err);
					this.loading = false;
				}
			);
		}
	}

	 arraytoFile(data, fileName: string, contentType: string) {
		var sliceSize = 512;
		var byteCharacters = atob(data);
		var byteArrays = [];

		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);
			var byteNumbers = new Array(slice.length);

			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			var byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
		var file= new File(byteArrays, fileName, {type: contentType});

		saveAs(new Blob(byteArrays, {type: contentType}),fileName)
		return file;
	}
}
