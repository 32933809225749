import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from 'src/app/core/layout/header/header.component';
import { HomeComponent } from './home/home.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { PasswordForgotComponent } from './password-forgot/password-forgot.component';
import { LoginShellComponent } from './login-shell/login-shell.component';


@NgModule({
  declarations: [
    LoginComponent,
    HeaderComponent,
    HomeComponent,

    PasswordResetComponent,
    PasswordForgotComponent,
    LoginShellComponent
  ],
  imports: [
    AppRoutingModule,
    SharedModule,
  ],
  exports: [
    HeaderComponent,
  ]
})
export class HomeModule { }
