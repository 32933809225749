import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-data-year',
	templateUrl: './data-year.component.html',
	styleUrls: ['./data-year.component.scss'],
})
export class DataYearComponent implements OnInit, OnDestroy {
	@Input() submit$: Subject<any> = new Subject();
	@Output() formValue = new EventEmitter<any>();

	form: FormGroup;

	maxYear = new Date().getFullYear() + 1;
	years = Array.from({ length: 17 }, (_, k) => this.maxYear - k);

	unsubscribe$: Subject<any> = new Subject();

	constructor(private formBuilder: FormBuilder) {}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			year: [new Date().getFullYear(), Validators.required],
		});

		this.submit$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
			if (this.form.valid) {
				this.formValue.emit(this.form.value);
			} else {
				this.formValue.emit(false);
			}
		});
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
