<section class="empty-report">

	<div class="reportContainer">
		<div class="reportContainer__image" style="background-image: url('assets/images/reports/IPM.jpg');"></div>
		<div class="reportContainer__content">
			<h2 class="reportContainer__content__heading">
				Investment Planning Models &amp; Reports
			</h2>
			<p class="reportContainer__content__copy">
				Our Investment planning model report provides you with the ability to understand the potential of your facility or site you operate from to get a greater understanding of the changing demographic and area potential.
			</p>
			<p class="reportContainer__content__cta">
				Talk to your Customer Success consultant at ActiveXchange today to discuss your next project.
			</p>
		</div>
	</div>

	<div class="reportContainer">
		<div class="reportContainer__image" style="background-image: url('assets/images/reports/SocialValue.jpg');"></div>
		<div class="reportContainer__content">
			<h2 class="reportContainer__content__heading">
				Social Value Reports
			</h2>
			<p class="reportContainer__content__copy">
				Communicating value in a new way, our Social Value Report helps Aquatics and Leisure facilities quantify the value they provide to the community more fully, and to help make a better case for ROI
			</p>
			<p class="reportContainer__content__cta">
				Talk to your Customer Success consultant at ActiveXchange today to discuss your next project.
			</p>
		</div>
	</div>

</section>
