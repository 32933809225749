import { Injectable } from '@angular/core';
import { MessageService } from './message.service';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Api } from '../../configs/config';
import { UserDto } from 'src/app/shared/models/userDto';
import { KeyValuePair } from 'src/app/shared/models/keyvaluepair';
import { AuthService } from 'src/app/core/services/auth.service';
import { MovementEmailSubscriptionDto } from 'src/app/shared/models/user/movementEmailSubscriptionDto.model';
import { CacheKeys } from 'src/app/configs/cacheKeys';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  operator = ['Operator', 'Operator (Pro)', 'Operator_Pro'];

  constructor(private http: HttpClient, private messageService: MessageService, private authService: AuthService) {}

  registerUser(
    username: string,
    pwd: string,
    firstName: string,
    lastName: string,
    jobTitleInd: number,
    jobTitleOther: string,
    role: string,
    organisationId: number,
    siteGroup: number
  ) {
    return this.http.post<any>(Api.postUserUrl, {
      Email: username,
      Password: pwd,
      FirstName: firstName,
      LastName: lastName,
      JobTitleInd: jobTitleInd,
      JobTitleOther: jobTitleOther,
      Role: role,
      OrganisationId: organisationId,
      SiteGroup: siteGroup,
    });
  }

  updateUser(
    userId: string,
    username: string,
    firstName: string,
    lastName: string,
    jobTitleInd: number,
    jobTitleOther: string,
    role: string,
    organisationId: number,
    lockIt: boolean,
    siteGroup: string
  ) {
    return this.http.put<any>(Api.postUserUrl, {
      UserId: userId,
      Email: username,
      FirstName: firstName,
      LastName: lastName,
      JobTitleInd: jobTitleInd,
      JobTitleOther: jobTitleOther,
      Role: role,
      OrganisationId: organisationId,
      LockIt: lockIt,
      SiteGroup: siteGroup,
    });
  }

  changepwd(userName: string, oldPwd: string, pwd: string) {
    return this.http.put<any>(Api.passwordChangeUrl, { UserName: userName, OldPassword: oldPwd, NewPassword: pwd });
  }

  getUserByName(username: string): Observable<UserDto> {
    return this.http.get<UserDto>(Api.getUserUrl.replace('{id}', username));
  }
  getUserById(userId: string): Observable<UserDto> {
    return this.http.get<UserDto>(Api.getUserUrl.replace('{id}', userId));
  }

  getRoles(): Observable<KeyValuePair[]> {
    return this.http.get<KeyValuePair[]>(Api.getIdentityRoles);
  }

  addUserOganisation(userName: string, organisationId: number): Observable<any> {
    return this.http.post<any>(Api.postUserOrganisationUrl.replace('{id}', userName), {
      UserName: userName,
      OrganisationId: organisationId,
    });
  }

  enableUser(userName: string) {
    return this.http.put<any>(Api.putEnableUserUrl, { UserName: userName });
  }
  disableUser(userName: string) {
    return this.http.put<any>(Api.putDisUserUrl, { UserName: userName });
  }

  checkSiteGroupAccess(): boolean {
    return this.operator.includes(this.authService.getPlatform()) || this.checkAdminSiteGroupAccess();
  }

  checkAdminSiteGroupAccess(): boolean {
    return (
      this.authService.isUserSportsEyeAdmin() &&
      this.operator.includes(sessionStorage.getItem(CacheKeys.AdminOrgPlatform))
    );
  }

  isAdminPlatformSportsPro() {
    let isAdmin = this.authService.isUserSportsEyeAdmin();
    let adminOrgPlatform = sessionStorage.getItem(CacheKeys.AdminOrgPlatform);
    if (isAdmin && adminOrgPlatform === 'Sports_Pro') {
      return true;
    } else return false;
  }

  getMovementEmailSubscription(): Observable<MovementEmailSubscriptionDto> {
    return this.http.get<MovementEmailSubscriptionDto>(Api.userMovementEmailSubscription);
  }

  updateMovementEmailSubscription(data: MovementEmailSubscriptionDto): Observable<any> {
    return this.http.post(Api.userMovementEmailSubscription, data);
  }

  errorHandler(err: any): Observable<any> {
    if (err.status == 401) this.messageService.showWarning('UnAuthorised');
    else {
      console.log('Error' + err);
      if (err.error) {
        if (err.error.errors) this.messageService.showError(err.error.errors);
        else this.messageService.showError(err.error);
      } else this.messageService.showError('Something went wrong.');
    }

    return of({});
  }
}
