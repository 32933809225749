<mat-card
	class="mat-elevation-z0 h-100"
	[ngClass]="{ accented: accent, 'hovered mat-elevation-z3': hovered }"
	(mouseover)="hovered = true"
	(mouseout)="hovered = false"
	style="background: url('{{ image }}') no-repeat center center; background-size: cover;">
	<mat-card-content class="p-3 w-50 h-100" style="background: rgba(245, 244, 255, 0.95)">
		<p class="fw-bold text-uppercase mb-2 mat-small date-text" *ngIf="date">{{ date | date : 'd MMMM' }}</p>
		<mat-card-title class="lh-1">{{ title }}</mat-card-title>
		<p>{{ body }}</p>
	</mat-card-content>
</mat-card>
