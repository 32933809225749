<header>
	<mat-icon>home</mat-icon>
	<h2>{{ modalState.siteName }}</h2>
	<button mat-icon-button aria-label="close dialog" disableRipple mat-dialog-close tabindex="-1">
		<mat-icon>close</mat-icon>
	</button>
</header>

<!-- VIEWS -->
<content>
	<!-- Tabbed Navigation Bar-->
	<div class="tab-bar" *ngIf="!modalState.editMode">
		<div
			*ngFor="let tab of tabs"
			class="tab-bar__item"
			(click)="changeTab(tab)"
			[ngClass]="{ 'tab-bar__item--active': modalState.currentTab === tab }">
			{{ tab }}
		</div>

		<div class="tab-bar__item tab-bar__item--last">
			<mat-icon
				*ngIf="canMonitorMovement"
				style="margin-right: 20px"
				aria-label="Monitor movement"
				matTooltip="Monitor movement"
				matTooltipPosition="left"
				matTooltipShowDelay="500"
				(click)="monitorForMovement()"
				>transfer_within_a_station</mat-icon
			>

			<mat-icon
				aria-label="Edit this record"
				matTooltip="Edit this record"
				matTooltipPosition="left"
				matTooltipShowDelay="500"
				(click)="editRecord()"
				>create</mat-icon
			>
		</div>
	</div>

	<!-- Site View Tab -->
	<section [hidden]="modalState.currentTab !== 'Site'">
		<app-site-view
			[hidden]="modalState.editMode"
			[siteData]="modalState"
			(lastUpdatedDateEvent)="updateDate($event)"></app-site-view>
		<app-site-edit
			*ngIf="modalState.editMode"
			[siteData]="modalState"
			(editRecordCloseEvent)="editRecordCloseEvent($event)"></app-site-edit>
	</section>

	<!-- Facilities Tab -->
	<section [hidden]="modalState.currentTab !== 'Facilities'">
		<app-facility-view [hidden]="modalState.editMode" [siteData]="modalState"></app-facility-view>
		<app-facility-edit
			*ngIf="modalState.editMode"
			[siteData]="modalState"
			(editRecordCloseEvent)="editRecordCloseEvent($event)"></app-facility-edit>
	</section>

	<!-- Action Plans Tab -->
	<section [hidden]="modalState.currentTab !== 'Action Plans'">
		<app-action-plan-view [hidden]="modalState.editMode" [siteData]="modalState"></app-action-plan-view>
		<app-action-plan-edit
			*ngIf="modalState.editMode"
			[siteData]="modalState"
			(editRecordCloseEvent)="editRecordCloseEvent($event)"></app-action-plan-edit>
	</section>

	<div class="footer" [hidden]="modalState.editMode">Last updated on {{ lastUpdateOn | date }}</div>
</content>
