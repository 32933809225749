import { Component } from '@angular/core';
import { CardComponent } from '../card/card.component';

@Component({
	selector: 'sportseye-card-date',
	templateUrl: './card-date.component.html',
	styleUrls: ['./card-date.component.scss']
})
export class CardDateComponent extends CardComponent {
	super() { }
}
