import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { Api } from 'src/app/configs/config';
import { CurrentFilters, NidFilter } from 'src/app/shared/models/Filter';
import { LayerData, MapPanel } from 'src/app/shared/models/mapPanel';
import { mapStartupParams } from 'src/app/shared/models/mapStartupParams';
import { SportsMemberDemandReq } from 'src/app/shared/models/sportsDemand';
import { MapMessaging } from './mapMessage';
import { SidebarMessaging } from './sidebarMessage';
import { map } from 'rxjs/operators';
import { NidSiteDto } from 'src/app/shared/models/nid-site-dto.model';
import * as Mapboxgl from 'mapbox-gl';
import { MapDataDto, MapLayerDto, GeoJsonSourceDto } from 'src/app/shared/models/maps';

@Injectable({
	providedIn: 'root',
})
export class MapService {
	public showFilters$: Subject<boolean> = new Subject<boolean>();
	public filters$: Subject<NidFilter> = new Subject<NidFilter>();
	public currentFiltes$: Subject<CurrentFilters[]> = new Subject<CurrentFilters[]>();
	public mapLayer$: Subject<LayerData> = new Subject<LayerData>();

	// New Global State Variables
	currentlyShowingNIDClusters: boolean = false;
	currentlyShowingNIDPins: boolean = false;
	currentlyShowingNewSites: boolean = false;
	mapMode: MapMessaging.Enums.MapComponentMode = MapMessaging.Enums.MapComponentMode.Normal;

	map: Mapboxgl.Map;
	mapLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	popup = new Mapboxgl.Popup({
		closeButton: false,
		closeOnClick: true,
		className: 'map-popup',
	});

	/** Constructor */
	constructor(private http: HttpClient) {}

	// -- New Message Passing Pipeline
	// -- Single source of messages to communicate with MAP component
	private mapMessageSource = new Subject<MapMessaging.MapMessage>();
	mapMessages$ = this.mapMessageSource.asObservable();

	sendMessageToMap(message: MapMessaging.MapMessage) {
		this.mapMessageSource.next(message);
	}

	// -- Single source of messages to communicate with MAP PANEL component
	private sidebarMessageSource = new Subject<SidebarMessaging.SidebarMessage>();
	sidebarMessages$ = this.sidebarMessageSource.asObservable();

	sendMessageToSidebar(message: SidebarMessaging.SidebarMessage) {
		this.sidebarMessageSource.next(message);
	}

	/** Helper method to set state of loading indicator overlay on the map */
	showLoadingIndicatorOverlayOnMap(show: boolean) {
		let actionType: MapMessaging.Enums.VisibilityActionTypeInd = show
			? MapMessaging.Enums.VisibilityActionTypeInd.Show
			: MapMessaging.Enums.VisibilityActionTypeInd.Hide;

		this.sendMessageToMap({
			messageType: MapMessaging.Enums.MapMessageTypeInd.LoadingIndicator,
			messageData: { visibilityType: actionType },
		});
	}

	// -- Legacy Message Passing Methods (try to replace these with the new versions above ASAP)
	setMapLayer(v: LayerData) {
		this.mapLayer$.next(v);
	}
	getMapLayer(): Observable<LayerData> {
		return this.mapLayer$.asObservable();
	}
	clearMapLayer() {
		this.mapLayer$.next();
	}

	addToCurrentFilters(f: CurrentFilters[]) {
		this.currentFiltes$.next(f);
	}
	addFilter(filter: NidFilter) {
		this.filters$.next(filter);
	}

	setShowFilters(s: boolean) {
		this.showFilters$.next(s);
	}

	getMapPanelUI(): Observable<MapPanel[]> {
		return this.http.get<MapPanel[]>(Api.getMapPanelUI_Url);
	}

	getSites(): Observable<any> {
		return this.http.get<any>(Api.getSitePoints);
	}

	getOrganisationSites(id: number): Observable<any> {
		return this.http.get<any>(Api.getOrganisationSitePoints.replace('{organisationId}', id.toString()));
	}

	getOrganisationSitesWithActionPlans(id: number): Observable<any> {
		return this.http.get<any>(Api.getOrganisationSitePointsWithActionPlans.replace('{organisationId}', id.toString()));
	}

	getSitesWithFilter(filter: NidFilter): Observable<any> {
		return this.http.post<NidFilter>(Api.getSitePointsWithFilters, filter);
	}

	getCircleMapDataFromAPI(apiEndpointUrl: string) {
		return this.http.get<any>(Api.baseApiUrl + '/api/v1' + apiEndpointUrl);
	}

	getFillMapDataFromAPI(apiEndpointUrl: string): Observable<any> {
		return this.http.get<any>(Api.baseApiUrl + '/api/v1' + apiEndpointUrl);
	}

	// Get Map Startup Params
	mapStartupData$ = new ReplaySubject<mapStartupParams>();
	mapStartupData: mapStartupParams;

	getMapStartup(): Observable<mapStartupParams> {
		return this.http.get<mapStartupParams>(Api.getMapStartupParams).pipe(
			map((data: mapStartupParams) => {
				this.mapStartupData$.next(data);
				this.mapStartupData = data;
				return this.mapStartupData;
			})
		);
	}

	getFilteredNidSiteList(searchKey: string): Observable<NidSiteDto[]> {
		return this.http.get<NidSiteDto[]>(Api.getFilteredNidSiteListUrl.replace('{searchKey}', searchKey));
	}

	addComma(x: number): string {
		if (isNaN(x)) return '-';

		if (x > 1000) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

		if (Number.isInteger(x)) return x.toString();

		return x.toFixed(2).toString();
	}

	getModalContentForLayerId(modalConfigName: string, layerData: any): string {
		const modalConfig = this.mapStartupData.modalConfigs[modalConfigName];

		let newContent = '';

		modalConfig.tables.forEach((table) => {
			newContent += '<section class="data-popup__section">';
			newContent += '  <h2>' + table.title + '</h2>';
			newContent += '  <table class="data-popup__table">';

			newContent += '    <thead>';
			newContent += '    <tr>';
			table.headings.forEach((heading) => {
				newContent += '<th>' + heading + '</th>';
			});
			newContent += '    </tr>';
			newContent += '    </thead>';

			newContent += '    <tbody>';
			table.rows.forEach((row) => {
				newContent += '<tr>';
				for (let i = 0; i < row.length; i++) {
					if (i === 0) {
						newContent += '<td>' + row[i] + '</td>';
					} else {
						newContent += '<td>' + layerData[row[i]] + '</td>';
					}
				}
				newContent += '</tr>';
			});
			newContent += '    </tbody>';

			newContent += '  </table>';
			newContent += '</section>';
		});

		return '<div class="data-popup">' + newContent + '</div>';
	}

	/**
	 * Create a tooltip from layer data
	 * @param tooltipName
	 * @param layerProperties
	 */
	createTooltip(tooltipName: string, layerProperties: any, useWider = true): string {
		let content = '';
		const tooltip = this.mapStartupData.tooltipConfigs[tooltipName];
		tooltip.items.forEach((item) => {
			const cssWider = useWider ? 'wider' : '';
			const nameSpan = item.name.length > 0 ? `<span>${item.name}</span>` : '';

			const itemValue = layerProperties[item.tilesetPropertyName] ? layerProperties[item.tilesetPropertyName] : '-';
			const parsedValue = this.tryParseJSON(itemValue);
			if (Array.isArray(parsedValue)) {
				parsedValue.forEach((value) => {
					content +=
					`<div class="data-row">
                    	${nameSpan}
                    	<span class="${cssWider}">${isNaN(parseInt(value)) || isNaN(value) ? value : this.addComma(value)}</span>
                  	</div>`;
				});
			} else {
				content +=
				`<div class="data-row">
					${nameSpan}
					<span class="${cssWider}">${isNaN(parseInt(itemValue)) || isNaN(itemValue) ? itemValue : this.addComma(itemValue)}</span>
			  	</div>`;
			}
		});

		return this.createPopup(
			layerProperties[tooltip.titleTilesetProperty],
			content,
			tooltip.footerText,
			tooltip.cssModifier
		);
	}

	/**
	 * Create a popup
	 * @param title Title of the popup
	 * @param content HTML contents of the popup
	 * @param cssModifier Optional CSS modifier in as the M of BEM (block__element--modifier) (just supply the modifier)
	 */
	private createPopup(title: string, content: string, footerText: string, cssModifier?: string): string {
		let header = `<div class="map-popup__header">${title}</div>`;
		let wrapper = `<div class="map-popup__content ${cssModifier ? 'map-popup__content--' + cssModifier : ''}">
                     ${content}
                   </div>`;

		let footer = '';
		if (footerText?.length > 0) {
			footer = `<div class="map-popup__footer">${footerText}</div>`;
		}

		return header + wrapper + footer;
	}

	getSportsMemberDemand(req: SportsMemberDemandReq): Observable<any> {
		return this.http.post(Api.getSportsMemberDemandUrl, req);
	}

	/** Get Member Demand Data for Map */
	getMemberDemandData(organisationId: number, geographicLevel: number): Observable<DemandConversionForMapDto[]> {
		let url = Api.getMemberDemandDataUrl
			.replace('{organisationId}', organisationId.toString())
			.replace('{geographicLevel}', geographicLevel.toString());

		return this.http.get<DemandConversionForMapDto[]>(url);
	}

	createMap(data: mapStartupParams): void {
		this.map = new Mapboxgl.Map({
			container: 'map',
			accessToken: data.accessToken,
			style: data.baseStyleURL,
			preserveDrawingBuffer: true,
		});
		this.map.on('load', () => {
			// -- add base layer data source for member and demand mapping
			this.map.addSource('BaseLayers', {
				type: 'vector',
				url: data.baseTilesetURL,
			});
			this.mapLoaded$.next(true);
		});
	}

	removeMap(): void {
		this.map.remove();
		this.mapLoaded$.next(false);
	}

	addMapData(mapData: MapDataDto): void {
		// add sources
		if (mapData.sources) {
			mapData.sources.forEach((source: GeoJsonSourceDto) => {
				if (!this.map.getSource(source.id)) {
					const { id: _, ...newSource } = source;
					this.map.addSource(source.id, <Mapboxgl.AnySourceData>newSource);
				}
			});
		}

		// add layers
		if (mapData.layers) {
			mapData.layers.forEach((layer: MapLayerDto) => {
				let newLayer = <Mapboxgl.AnyLayer>{
					id: layer.id,
					type: layer.type,
					source: layer.source,
					...(layer.filter ? { filter: layer.filter } : {}),
					...(layer.sourceLayer ? { 'source-layer': layer.sourceLayer } : {}),
				};
				switch (layer.type) {
					case 'fill': {
						(<Mapboxgl.FillLayer>newLayer).paint = {
							'fill-color': layer.colour ?? ['get', 'colour'],
						};
						break;
					}
					case 'line': {
						(<Mapboxgl.LineLayer>newLayer).paint = {
							'line-color': layer.colour ?? ['get', 'colour'],
							'line-width': layer.lineWidth ?? 1.5,
						};
						break;
					}
					case 'symbol': {
						(<Mapboxgl.SymbolLayer>newLayer).layout = {
							'icon-image': layer.iconImage,
							'icon-padding': 0,
							'icon-size': 0.75,
							'icon-allow-overlap': true,
						};
						break;
					}
				}

				this.map.addLayer(newLayer);

				//setup layer tooltips
				if (mapData.tooltipConfigs[layer.id]) {
					this.map.on('mousemove', layer.id, (e) => {
						this.map.getCanvas().style.cursor = 'pointer';
						this.popup?.remove();

						const layerProperties =
							mapData.data && layer.idFieldName && layer.sourceFieldName
								? mapData.data.find((x) => x[layer.idFieldName] == e.features[0].properties[layer.sourceFieldName])
								: e.features[0].properties;
						const tooltipContent = this.createTooltip(layer.id, layerProperties);
						this.popup.setLngLat(e.lngLat).setHTML(tooltipContent).addTo(this.map);
					});

					// MOUSE LEAVE
					this.map.on('mouseleave', layer.id, () => {
						this.popup?.remove();
						this.map.getCanvas().style.cursor = '';
					});
				}
			});
		}

		// add tooltips to mapStartupData
		if (mapData.tooltipConfigs) {
			this.mapStartupData.tooltipConfigs = { ...this.mapStartupData.tooltipConfigs, ...mapData.tooltipConfigs };
		}
	}

	private tryParseJSON(jsonString: string): object | false {
		try {
			var result = JSON.parse(jsonString);
			if (result && typeof result === 'object') {
				return result;
			}
		} catch (_) {}

		return false;
	}
}

export interface DemandConversionForMapDto {
	id: number;
	name: string;
	noOfMembers: number;
	membersRatio: number;
	demand: number;
	demandRatio: number;
	demandConversionRate: number;
}

export interface FillMapDataBase {
	AREA_CODE: string;
}
