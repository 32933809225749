import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../core/services/auth.service';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  isComplete: boolean =  false;
  serverErrors: string[] = [];
	form = this.formBuilder.group({
		password: ['', Validators.required],
		confirmpassword: ['', Validators.required]
	}
);

  loading: boolean = false;
  matchedPolicy: boolean = true;

  resetPwdToken: string = "";

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private errHandlerService: ErrorHandlerService) {

  }

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      param=> {
        this.resetPwdToken = param.get('id')??"";
      }
    );
  }

  onStrengthChanged(strength: number) {
    window.setTimeout(() => {
      if (strength < 80) {
        this.matchedPolicy = false;
      } else {
        this.matchedPolicy = true;
      }
    });
  }

  handleError(err: any) {
    this.loading = false;
    console.log(err);
    this.serverErrors = this.errHandlerService.handleError(err);
  }

  onSubmit() {
    this.serverErrors = [];

    if ( this.form.valid && this.f.password.value == this.f.confirmpassword.value && this.f.password.value.length > 7) {
        this.ResetPassword();
        return false;
    } else {
      if (this.f.password.value != this.f.confirmpassword.value) {
        this.serverErrors.push('Both password should match.');
        console.log('Password does not match');
      }
      if (this.f.password.value.length < 8) {
        this.serverErrors.push('Password must be at least 8 characters');
      }
      return true;
    }
  }

  ResetPassword(){
    this.loading = true;

    this.authenticationService.resetPassword(this.resetPwdToken, this.f.password.value).subscribe(
      () => {
        this.loading = false;
        this.isComplete = true;

      }, (err) => this.handleError(err)
    );
  }

  navigate(){
    this.router.navigate(['/login']);
  }
}
