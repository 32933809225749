<div class="pageHeading">
	<div class="pageHeading__text">
		<div class="pageHeading__text__icon">
			<mat-icon>{{iconName}}</mat-icon>
		</div>
		<div class="pageHeading__text__text">
			<h1 class="pageHeading__text__text__title">{{title}}</h1>
			<h2 class="pageHeading__text__text__subtitle">{{subTitle}}</h2>
		</div>
	</div>

	<div class="pageHeading__buttons">
		<ng-content></ng-content>
	</div>
</div>
