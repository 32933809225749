import { MovementMonitoringItemDto } from 'src/app/shared/models/movement';

export module SidebarMessaging {
	/**
	 * Sidebar Message
	 * Send a message to the MapSidebarComponent to alter behavior
	 * */
	export interface SidebarMessage {
		messageType: SidebarMessageTypeInd;
		messageData?: LegendMessageDataDTO | FilterMessageDataDTO | ShowMovementMonitoringModalDto;
	}

	/** Message Types */
	export enum SidebarMessageTypeInd {
		Legend,
		Filter,
		RefreshMovemementMonitoring,
		ShowMovementMonitoringModal,
		ShowNewSites,
	}

	/** Available Legend Types */
	export type LegendType = 'circle' | 'fill' | 'line' | 'symbol';

	/** Legend Message DTO */
	export class LegendMessageDataDTO {
		type: LegendType;
		startColor: string;
		endColor?: string;
		startValue?: number;
		endValue?: number;
		midValue?: number;
		show: boolean = false;

		constructor(public buttonContainerId: string, public title: string) {}

		public setColours(start: string, end: string) {
			this.startColor = start;
			this.endColor = end;
		}

		public setValues(start: number, end: number) {
			this.startValue = start;
			this.endValue = end;
			this.midValue = end > 0 ? end / 2 + 1 : 0;
		}
	}

	export class FilterMessageDataDTO {}

	export interface ShowMovementMonitoringModalDto {
		item: MovementMonitoringItemDto;
		isDirty: boolean;
		isNew: boolean;
	}
}
