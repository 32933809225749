<!-- Login Page Container -->
<section class="loginPageContainer">

  <!-- Left Login Panel -->
  <section class="loginPageContainer__contentPanel">
    <div class="loginPageContainer__contentPanel__wrapper">
			<div class="theme__images--productLogoLogin"></div>
      <router-outlet></router-outlet>
    </div>
  </section>

  <!-- Right Image Panel -->
  <section class="loginPageContainer__imagePanel" [style.background-image]="'url(' + randomImagePath + ')'"></section>
</section>
