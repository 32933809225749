import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';

import { EtlService } from 'src/app/core/services/etl.service';
import { AuthService } from '../../../core/services/auth.service';

import BaseComponent from 'src/app/core/base/base-component.component';

import { CacheKeys } from 'src/app/configs/cacheKeys';
import { JobHistory } from 'src/app/shared/models/jobHistory';
import { AxTableColumn } from '../ax-table';

@Component({
  selector: 'app-job-history',
  templateUrl: './job-history.component.html',
  styleUrls: ['./job-history.component.scss'],
})
export class JobHistoryComponent extends BaseComponent implements OnInit {
  displayedColumnsDesktop: Array<string> = [
    'jobId',
    'jobTypeIndStr',
    'fileName',
    'createdDateTimeUTC',
    'statusDateTimeUTC',
    'statusIndStr',
  ];

  displayedColumnsAdmin: Array<string> = [
    'jobId',
    'jobTypeIndStr',
    'fileName',
    'createdDateTimeUTC',
    'statusDateTimeUTC',
    'datayear',
    'statusIndStr',
  ];

  columns: Array<AxTableColumn<JobHistory>> = [
    {
      columnDef: 'jobId',
      header: 'JOB ID',
      cellDataType: 'string',
      cellDataFn: (element: JobHistory) => `${element.jobId}`,
      columnFilterOptionsFn: () => {
        return [...new Set(this.jobHistories.map((job: JobHistory) => `${job.jobId}`))];
      },
    },
    {
      columnDef: 'jobTypeIndStr',
      header: 'FILE TYPE',
      cellDataType: 'string',
      cellDataFn: (element: JobHistory) => `${element.jobTypeIndStr}`,
      columnFilterOptionsFn: () => {
        return [...new Set(this.jobHistories.map((job: JobHistory) => job.jobTypeIndStr))];
      },
    },
    {
      columnDef: 'fileName',
      header: 'FILE NAME',
      cellDataType: 'string',
      cellDataFn: (element: JobHistory) => `${element.fileName}`,
      columnFilterOptionsFn: () => {
        return [...new Set(this.jobHistories.map((job: JobHistory) => job.fileName))];
      },
    },
    {
      columnDef: 'createdDateTimeUTC',
      header: 'DATE / TIME UPLOADED (UTC)',
      cellDataType: 'date',
      cellDataFn: (element: JobHistory) => `${element.createdDateTimeUTC}`,
      dateFormatString: 'dd/MM/yyyy hh:mm a',
    },
    {
      columnDef: 'datayear',
      header: 'DATA YEAR',
      cellDataType: 'string',
      cellDataFn: (element: JobHistory) => `${element.datayear}`,
    },
    {
      columnDef: 'statusDateTimeUTC',
      header: 'STATUS DATE / TIME (UTC)',
      cellDataType: 'date',
      cellDataFn: (element: JobHistory) => `${element.statusDateTimeUTC}`,
      dateFormatString: 'dd/MM/yyyy hh:mm a',
    },
    {
      columnDef: 'statusIndStr',
      header: 'JOB STATUS',
      cellDataType: 'string',
      cellDataFn: (element: JobHistory) => `${element.statusIndStr}`,
      cellStyleFn: (element: JobHistory) => {
        let style =
          'padding: 5px;color: white;text-transform: uppercase;font-size: 9pt;font-weight: bold;text-align:center;';

        // Submitted
        if (element.statusInd === 1) {
          style += 'background-color: #fc9403;';
        }

        // Processing
        if (element.statusInd > 1 && element.statusInd <= 99) {
          style += 'background-color: #1f78d1;';
        }

        // Error
        if (element.statusInd >= 1000 && element.statusInd < 9999) {
          style += 'background-color: #db3b21;';
        }

        // Deleted
        if (element.statusInd === 9999) {
          style += 'background-color: #555;';
        }

        // Completed
        if (element.statusInd === 100) {
          style += 'background-color: #1aaa55;';
        }

        return style;
      },
      columnFilterOptionsFn: () => {
        return [...new Set(this.jobHistories.map((job: JobHistory) => job.statusIndStr))];
      },
    },
  ];

  jobHistories: Array<JobHistory>;

  @Input() organisationId: number;
  @Output() rowClicked = new EventEmitter<JobHistory>();

  isSports: boolean = false;

  constructor(private etlService: EtlService, private authService: AuthService, private router: Router) {
    super();
  }

  ngOnInit(): void {
    let platform = '';
    if (!this.authService.isUserSportsEyeAdmin()) {
      platform = this.authService.getPlatform();
    } else {
      platform = sessionStorage.getItem(CacheKeys.AdminOrgPlatform);
    }

    if (platform === 'Sports' || platform === 'Sports_Pro' || platform === 'Sports_PeakBody') {
      this.isSports = true;
    }

    this.loadJobHistory(this.organisationId);
  }

  public loadJobHistory(organisationId: number) {
    if (!organisationId) {
      return;
    }

    this.loading = true;
    this.etlService
      .getJobHistoryForOrganisation(organisationId.toString())
      .pipe(takeUntil(this.subscriptionComplete))
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((data: JobHistory[]) => {
        this.jobHistories = data;
      });
  }

  showJobHistory(job: JobHistory) {
    this.router.navigate(['/admin/organisations/' + this.organisationId + '/jobs/' + job.jobId]);
  }
}
