<!-- Loading Indicator -->
<app-loading-indicator
	*ngIf="loading"
	text="Loading Facilities">
</app-loading-indicator>

<!-- Empty State Information -->
<div *ngIf="!loading && facilities?.length == 0" style="text-align: center; padding-bottom: 50px;">
	<app-empty-state
	   notificationText="No Facilities Yet"
	   callToActionText="Add facilities (pools, studios, fields, courts) to a site and then assign usages to those facilities."
		iconName="pool">
	</app-empty-state>

	<app-icon-button icon="add_circle" text="ADD A FACILITY" size="large" (buttonClicked)="addNew()"></app-icon-button>
</div>

<!-- Screen Layout -->
<section *ngIf="!loading && facilities?.length > 0" class="nid-modal__tab-panel nid-modal__split-view">

	<!-- Existing Facility List (Left Column)-->
	<section class="nid-modal__split-view--list">
		<h3 class="sectionTitles">LIST OF FACILITIES</h3>

		<ul>
			<li *ngFor="let facility of facilities" [class.active]="facility === currentFacility">
				<span class="text-container" (click)="showFacility(facility)">
					<span class="name">{{facility.name}}</span>
					<span class="type">{{facility.spaceTypeIndStr}} - {{facility.surfaceTypeIndStr}} ({{facility.settingTypeIndStr}})</span>
				</span>
				<mat-icon
					aria-label="Delete this Facility"
					matTooltip="Delete this facility"
					matTooltipPosition="left"
					matTooltipShowDelay="500"
					(click)="removeFacility(facility)">
					clear
				</mat-icon>
			</li>
		</ul>

		<app-icon-button icon="add_circle" text="ADD FACILITY" size="small"
			(buttonClicked)="addNew()">
		</app-icon-button>
	</section>


	<!-- Selected Facility Information -->
	<section class="nid-modal__split-view--content" *ngIf="facilities.length > 0">
		<span [ngStyle]="{'background-color': getStatusColor(currentFacility.statusInd)}"
			class="status-ind">{{currentFacility.statusIndStr}}</span>


		<section class="info-box">
			<div class="info-box__header">
				<mat-icon class="material-icons-outlined">description</mat-icon>
				<span>{{currentFacility.name}}</span>
			</div>

			<div class="info-box__content">
				<div class="info-box__table-wrapper">
					<dl>
						<dt>Space Type</dt>
						<dd>{{currentFacility.spaceTypeIndStr}}</dd>
					</dl>
					<dl>
						<dt>Surface / Setting</dt>
						<dd>{{currentFacility.surfaceTypeIndStr}} / {{currentFacility.settingTypeIndStr}}</dd>
					</dl>
					<dl>
						<dt>Built Year</dt>
						<dd>{{currentFacility.approxBuildYear}}</dd>
					</dl>
					<dl>
						<dt>Refurb Year</dt>
						<dd>{{currentFacility.approxLastRefurbYear}}</dd>
					</dl>
					<dl>
						<dt>Condition</dt>
						<dd>{{currentFacility.primaryBuiltConditionIndStr}}</dd>
					</dl>
					<dl>
						<dt>Avergage Cost</dt>
						<dd>{{currentFacility.averageHireCost}}</dd>
					</dl>
					<dl>
						<dt>Usage Level</dt>
						<dd>{{currentFacility.usageLevelIndStr}}</dd>
					</dl>
					<dl>
						<dt>Seating Capacity</dt>
						<dd>{{currentFacility.totalSpectatorSeatingCapacity}}</dd>
					</dl>
					<dl>
						<dt>Seating Condition</dt>
						<dd>{{currentFacility.spectatorSeatingConditionIndStr}}</dd>
					</dl>
					<dl *ngIf="currentFacility.hasFloodLighting">
						<dt>Flood Lighting</dt>
						<dd>{{currentFacility.hasFloodLighting==true ? 'Yes': 'No'}}</dd>
					</dl>
					<dl *ngIf="currentFacility.hasFloodLighting">
						<dt>Lux Level</dt>
						<dd>{{currentFacility.lightingLuxLevel}}</dd>
					</dl>
					<dl>
						<dt>Change Rooms</dt>
						<dd>{{currentFacility.totalDedicatedChangingRooms}}</dd>
					</dl>
				</div>
			</div>
		</section>

		<!-- METADATA -->
		<section class="info-box" *ngIf="currentFacility.nidFacilityMetadataForSpaceTypeSummary?.length > 0">
			<div class="info-box__header">
				<mat-icon class="material-icons-outlined">data_object</mat-icon>
				<span>Metadata</span>
			</div>
			<div class="info-box__content">
				<div class="info-box__table-wrapper">
					<dl *ngFor="let metadata of currentFacility.nidFacilityMetadataForSpaceTypeSummary; index as i">
						<dt>{{metadata.question}}</dt>
						<dd>{{metadata.answer}} {{metadata.suffix}}</dd>
					</dl>
				</div>
			</div>
		</section>


		<!-- OPERATING MONTHS -->
		<h3 class="sectionTitles">OPERATING MONTHS</h3>
		<section>
			<div class="pills">
				<span class="pills__pill pills__pill--first"
					[ngStyle]="{'background-color': checkFlag(currentFacility.usageMonthFlag,1) ? '#11944F': '#DADADA'}">JAN</span>
				<span class="pills__pill"
					[ngStyle]="{'background-color': checkFlag(currentFacility.usageMonthFlag,2) ? '#11944F': '#DADADA'}">FEB</span>
				<span class="pills__pill"
					[ngStyle]="{'background-color': checkFlag(currentFacility.usageMonthFlag,4) ? '#11944F': '#DADADA'}">MAR</span>
				<span class="pills__pill"
					[ngStyle]="{'background-color': checkFlag(currentFacility.usageMonthFlag,8) ? '#11944F': '#DADADA'}">APR</span>
				<span class="pills__pill"
					[ngStyle]="{'background-color': checkFlag(currentFacility.usageMonthFlag,16) ? '#11944F': '#DADADA'}">MAY</span>
				<span class="pills__pill"
					[ngStyle]="{'background-color': checkFlag(currentFacility.usageMonthFlag,32) ? '#11944F': '#DADADA'}">JUN</span>

				<span class="pills__pill"
					[ngStyle]="{'background-color': checkFlag(currentFacility.usageMonthFlag,64) ? '#11944F': '#DADADA'}">JUL</span>
				<span class="pills__pill"
					[ngStyle]="{'background-color': checkFlag(currentFacility.usageMonthFlag,128) ? '#11944F': '#DADADA'}">AUG</span>
				<span class="pills__pill"
					[ngStyle]="{'background-color': checkFlag(currentFacility.usageMonthFlag,256) ? '#11944F': '#DADADA'}">SEP</span>
				<span class="pills__pill"
					[ngStyle]="{'background-color': checkFlag(currentFacility.usageMonthFlag,512) ? '#11944F': '#DADADA'}">OCT</span>
				<span class="pills__pill"
					[ngStyle]="{'background-color': checkFlag(currentFacility.usageMonthFlag,1024) ? '#11944F': '#DADADA'}">NOV</span>
				<span class="pills__pill pills__pill--last"
					[ngStyle]="{'background-color': checkFlag(currentFacility.usageMonthFlag,2048) ? '#11944F': '#DADADA'}">DEC</span>

			</div>
		</section>

		<!-- USAGES -->
		<h3 class="sectionTitles">USAGES OF THIS {{currentFacility.spaceTypeIndStr}}</h3>

		<div class="empty-usages" *ngIf="facilityUsages?.length == 0">
			<mat-icon>sports_tennis</mat-icon>
			<div>
				<h2>No usages added yet</h2>
				<p>Add the known or planned usages of this facility to help others locate infrastructure assets that can be used by a sport / activity.</p>
				<app-icon-button icon="add_circle" text="ADD A FACILITY USAGE" size="large" (click)="newUsage()"></app-icon-button>
			</div>
		</div>

		<div *ngIf="facilityUsages?.length > 0" class="usage-table-container">
			<ul>
				<li *ngFor="let usage of facilityUsages" class="usage-table">
					<span class="usage-table__quantity">{{usage.quantity}}</span>
					<span class="usage-table__activity">{{usage.axActivityProgramme.axActivity.name}} ({{usage.axActivityProgramme.name}})</span>
					<span class="usage-table__compliance">{{usage.competitionComplianceIndStr}}</span>
					<span class="usage-table__buttons">
						<mat-icon class="edit" (click)="editUsage(usage.id)">edit</mat-icon>
						<mat-icon class="delete" (click)="removeUsage(usage)">clear</mat-icon>
					</span>
				</li>
			</ul>

			<div style="text-align: right">
				<app-icon-button icon="add_circle" text="ADD FACILITY USAGE" size="small" (click)="newUsage()"></app-icon-button>
			</div>
		</div>

		<!-- COMMENTS -->
		<div style="margin-top: 20px" *ngIf="currentFacility.comments?.length > 0">
			<h3 class="sectionTitles">COMMENTS</h3>
			<p>{{currentFacility.comments}}</p>
		</div>
	</section>
</section>
