import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
	selector: '[appDragAndDrop]',
})
export class DragAndDropDirective {
	@Input() dragDropDisabled: boolean;
	@Output() fileDropped = new EventEmitter<FileList>();
	@HostBinding('style.opacity') opacity: string;

	@HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
		event.preventDefault();
		event.stopPropagation();
		if (!this.dragDropDisabled) {
			this.opacity = '0.5';
		}
	}

	@HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
		event.preventDefault();
		event.stopPropagation();
		if (!this.dragDropDisabled) {
			this.opacity = '1';
		}
	}

	@HostListener('drop', ['$event']) ondrop(event: DragEvent) {
		event.preventDefault();
		event.stopPropagation();
		if (!this.dragDropDisabled) {
			this.opacity = '1';
			let files = event.dataTransfer!.files;
			if (files.length > 0) {
				this.fileDropped.emit(files);
			}
		}
	}
}
