<app-loading-indicator
	*ngIf="loading"
	text="Loading Action Plans">
</app-loading-indicator>

<div *ngIf="!loading && actionPlans?.length == 0" style="text-align: center; padding-bottom: 50px;">
 <app-empty-state
	notificationText="No Action Plans Yet"
	callToActionText="Add an action plan to this site to share details with other users on planned activity at this site."
 	iconName="summarize">
 </app-empty-state>

 <app-icon-button icon="add_circle" text="ADD AN ACTION PLAN" size="large" (buttonClicked)="addNew()"></app-icon-button>

</div>

<section *ngIf="!loading && actionPlans?.length > 0" class="nid-modal__tab-panel nid-modal__split-view">

	<section class="nid-modal__split-view--list">
		<h3 class="sectionTitles">LIST OF ACTION PLANS</h3>

		<ul>
			<li *ngFor="let actionPlan of actionPlans" [class.active]="actionPlan === currentActionPlan">
				<span class="text-container" (click)="showDetail(actionPlan)">
					<span class="name">
						{{actionPlan.typeIndStr}} - {{actionPlan.facilityTypeName}}
					</span>
				</span>
				<mat-icon
					aria-label="Delete this action plan"
					matTooltip="Delete this action plan"
					matTooltipPosition="left"
					matTooltipShowDelay="500"
					(click)="removeActionPlan(actionPlan)">
					clear
				</mat-icon>
			</li>
		</ul>

		<app-icon-button icon="add_circle" text="ADD ACTION PLAN" size="small"
			(buttonClicked)="addNew()">
		</app-icon-button>
	</section>


	<section class="nid-modal__split-view--content" *ngIf="actionPlans.length">

		<h2>{{currentActionPlan.name}}</h2>
		<h3 class="sectionTitles">OVERVIEW</h3>
		<p *ngIf="currentActionPlan.overview?.length > 0">{{currentActionPlan.overview}}</p>
		<p *ngIf="!currentActionPlan.overview"><i>No overview provided</i></p>

		<h3 class="sectionTitles">JUSTIFICATION</h3>
		<p *ngIf="currentActionPlan.justification?.length > 0">{{currentActionPlan.justification}}</p>
		<p *ngIf="!currentActionPlan.justification"><i>No justification provided</i></p>

		<section *ngIf="currentActionPlan.id!=0" class="info-box" style="margin-top: 30px;">
			<div class="info-box__header">
				<mat-icon>info</mat-icon>
				<span>More Details</span>
			</div>
			<div class="info-box__content">
				<div class="info-box__table-wrapper">
					<dl>
						<dt>Start Date</dt>
						<dd>{{currentActionPlan.startDateLocal | date: 'dd/MM/yyyy'}} </dd>
					</dl>
					<dl>
						<dt>End Date</dt>
						<dd>{{currentActionPlan.endDateLocal | date: 'dd/MM/yyyy'}}</dd>
					</dl>
					<!-- <dl>
						<dt>Facility Type</dt>
						<dd>{{currentActionPlan.facilityTypeName}} </dd>
					</dl>
					<dl>
						<dt>Facility Sub Type</dt>
						<dd>{{currentActionPlan.facilitySubTypeName}}</dd>
					</dl> -->
					<dl>
						<dt>Setting</dt>
						<dd>{{currentActionPlan.settingTypeName}} </dd>
					</dl>
					<dl>
						<dt>Space Type</dt>
						<dd>{{currentActionPlan.spaceTypeName}}</dd>
					</dl>
					<dl>
						<dt>Surface Type</dt>
						<dd>{{currentActionPlan.surfaceTypeName}}</dd>
					</dl>
					<dl>
						<dt>Type</dt>
						<dd>{{currentActionPlan.typeIndStr}}</dd>
					</dl>

					<dl>
						<dt>Estimated Cost</dt>
						<dd>{{currentActionPlan.estimatedCost}}</dd>
					</dl>
					<dl>
						<dt>Funding</dt>
						<dd>{{currentActionPlan.fundingIndStr}}</dd>
					</dl>

					<dl>
						<dt>Progress</dt>
						<dd>{{currentActionPlan.progressIndstr}}</dd>
					</dl>
					<dl>
						<dt>Priority</dt>
						<dd>{{currentActionPlan.projectPriorityIndStr}}</dd>
					</dl>

					<dl>
						<dt>Partner Name</dt>
						<dd>{{currentActionPlan.partnerOrganisationName}}</dd>
					</dl>
				</div>
			</div>
		</section>

		<section class="action-plan-comments">

			<!-- Existing Comments List -->
			<div class="action-plan-comments__list" *ngIf="actionPlanComments?.length > 0">
				<h3 class="sectionTitles">Comments</h3>
				<ul>
					<li *ngFor="let c of actionPlanComments">
						<p class="comment">{{c.comments}}</p>
						<div class="comment-footer">
							<span class="author">{{c.userId}}</span>
							<span class="date">{{c.dateTimeUTC | date: 'dd/MM/yy HH:mm'}}</span>
							<mat-icon (click)="removeComment(c.id)">clear</mat-icon>
						</div>
					</li>
				</ul>
			</div>


			<!-- Enter New Comments -->
			<div class="action-plan-comments__entry-form" *ngIf="currentActionPlan.allowComments">
				<h3 class="sectionTitles">HAVE YOUR SAY ...</h3>
				<mat-form-field appearance="fill" class="w-100">
					<mat-label>Provide feedback/comments about this action plan</mat-label>
					<textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"
						cdkAutosizeMaxRows="5" [(ngModel)]="comments"></textarea>
				</mat-form-field>
				<div style="text-align: right;">
					<app-icon-button
						size="small"
						icon="add_circle"
						text="POST COMMENT"
						(buttonClicked)="addComment()">
					</app-icon-button>
				</div>
			</div>
		</section>
	</section>

</section>
