import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
	selector: 'app-help-modal',
	templateUrl: './help-modal.component.html',
	styleUrls: ['./help-modal.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class HelpModalComponent {
	@Input() title: string;
	@Input() content: string;
	@Input() vimeoVideoUrl: string;

	constructor(private sanitizer: DomSanitizer) { }

	formatString(content: string): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(content);
	}

}
