import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { authGuard } from 'src/app/core/guards/auth.guard';
import { sportseyeAdminGuard } from 'src/app/core/guards/sportseye-admin.guard';
import { ReportsListComponent } from './reports-list/reports-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { GroupByPipe } from 'src/app/shared/pipes/groupBy';
import { EmptyReportComponent } from './empty-report/empty-report.component';

@NgModule({
	declarations: [
		ReportsListComponent,
		EmptyReportComponent,
		GroupByPipe
	],
	imports: [
		SharedModule,
		RouterModule.forChild([
            {
                path: '',
                component: ReportsListComponent, canActivate: [authGuard]
            },
            {path: 'reports', component: ReportsListComponent, canActivate: [authGuard, sportseyeAdminGuard]},
        ]),
	],
})
export class ReportsModule {}
