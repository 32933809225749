import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { AuthService } from '../../../core/services/auth.service';
import { MessageService } from '../../../core/services/message.service';

@Component({
  selector: 'app-password-forgot',
  templateUrl: './password-forgot.component.html',
  styleUrls: ['./password-forgot.component.scss']
})
export class PasswordForgotComponent implements OnInit {
  loading: boolean = false;
  isComplete: boolean = false;
  serverErrors: string[] = [];
  form = this.formBuilder.group({
    userName: ['', Validators.email],

  });


  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private messageService: MessageService,
    private authService: AuthService,
    private errHandlerService: ErrorHandlerService
    ) { }


  ngOnInit(): void {
  }


  get f(){
    return this.form.controls;
  }

  async onSubmit(){
    this.serverErrors = [];

    if (this.form.invalid)
    {
      this.serverErrors.push('Please input valid email address');
      return;
    }

    this.loading = true;
    this.authService.requestResetPassword(this.f.userName.value).subscribe(
      data => {
          this.loading = false;
          this.isComplete = true;
          //this.messageService.showInfo("Reset Password request has been sent.");

      }, (err)=> {
        this.handleError(err);
      }
    );



  }

  handleError(err: any)
  {
    this.loading = false;

    this.serverErrors = this.errHandlerService.handleError(err);


  }

  navigate(){
    this.router.navigate(['/login']);
  }
}
