import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { FileStatus } from './file-status.enum';

export class SelectedFile {
	file: File;
	status: FileStatus = FileStatus.Added;
	progress: number = 0;
	request: Subscription = null;
	response: HttpResponse<any> | HttpErrorResponse = null;

	constructor(file: File) {
		this.file = file;
	}
}
