import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { OrganisationService } from 'src/app/core/services/organisation.service';
import { UnmappedEntitySummaryDto } from 'src/app/shared/models/organisation';

@Component({
	selector: 'app-data-issues',
	templateUrl: './data-issues.component.html',
	styleUrls: ['./data-issues.component.scss']
})
export class DataIssuesComponent implements OnInit {
	loading: boolean = false;
	dataIssues: UnmappedEntitySummaryDto;
	organisationId: number = 0;
	tableData = [] as any;
	constructor(
		private orgService: OrganisationService,
		private authService: AuthService
	) { }

	displayedColumns: string[] = ['description', 'value'];

	ngOnInit(): void {
		this.organisationId = this.authService.getUserOrganisationId();
		this.getDataIssueSummary(this.organisationId);
	}

	getDataIssueSummary(orgId: number) {
		this.orgService.getUnmappedEntitySummary(this.organisationId).subscribe(
			data => {
				this.dataIssues = data;
				this.generateTable();
			}
		);
	}

	generateTable() {
		let tableData = [
			{ description: 'Unmapped Sites', value: this.dataIssues.totalUnmappedSites, link: '/map-sites' },
			{ description: 'Unmapped Membership Types', value: this.dataIssues.totalUnmappedMemberships, link: '/map-membership-type' },
			{ description: 'Unmapped Activities', value: this.dataIssues.totalUnmappedActivities, link: '/map-activity' },
			{ description: 'Total Issues', value: this.dataIssues.totalUnmappedActivities + this.dataIssues.totalUnmappedMemberships + this.dataIssues.totalUnmappedSites },
		];

		tableData = tableData.filter(row => row.value != 0);

		this.tableData = tableData
	}
}
