<header>
	<h2 class="d-flex gap-2 align-items-center">
		<mat-icon>cloud_upload</mat-icon>
		<span>{{ 'Upload File' + (dialogData.multiSelect ? 's' : '') }}</span>
	</h2>
	<button
		mat-icon-button
		aria-label="close dialog"
		disableRipple
		(click)="confirmClose()"
		matTooltip="Close"
		[matTooltipShowDelay]="500"
		tabindex="-1">
		<mat-icon>close</mat-icon>
	</button>
</header>

<content>
	<div class="container">
		<div class="d-flex flex-column gap-3">
			<ng-template #target></ng-template>
			<app-file-uploader
				[acceptedTypes]="dialogData.acceptedTypes ?? []"
				[multiSelect]="dialogData.multiSelect ?? false"
				[uploadRequest$]="uploadRequest$"
				(uploadStarted)="startUpload($event)"
				(allFilesUploaded)="allFilesUploaded = $event"
				(fileUploaded)="fileUploaded = true">
			</app-file-uploader>
		</div>
	</div>
</content>
