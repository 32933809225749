import { Component, inject, model, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ColumnFilterDialogData } from './column-filter-dialog-data';
import { MatSelectionList } from '@angular/material/list';

@Component({
  selector: 'app-ax-table-filter-dialog',
  templateUrl: './ax-table-filter-dialog.component.html',
  styleUrl: './ax-table-filter-dialog.component.scss',
})
export class AxTableFilterDialogComponent implements OnInit {
  @ViewChild('optionsList', { static: true }) private optionsList: MatSelectionList;
  readonly dialogRef = inject(MatDialogRef<AxTableFilterDialogComponent>);
  readonly data = inject<ColumnFilterDialogData>(MAT_DIALOG_DATA);
  readonly model = model(this.data.selectedOptions);

  filterString: string = '';

  private selectedOptions = this.data.selectedOptions;
  private allSelected = false;

  private originalData: Array<string>;

  ngOnInit(): void {
    this.originalData = this.data.options.slice();
  }

  // Handle type ahead filter change
  onFilterChange(event: Event): void {
    this.filterString = (event.target as HTMLInputElement).value;
    this.data.options = this.originalData.filter((option: string) =>
      option.toUpperCase().includes(this.filterString.toUpperCase())
    );
  }

  // Handle clear filter button
  onClearFiltersClick(): void {
    this.filterString = '';
    this.data.options = this.originalData.slice();
  }

  // Handle the model selection changed event so we can store actual values
  onSelectionChanged(selected: Array<string>): void {
    this.selectedOptions = selected;
  }

  // Check if a value has been selected
  isSelected(value: string): boolean {
    return this.selectedOptions?.includes(value) ?? false;
  }

  // Handle select all button click
  onToggleSelectAll(): void {
    if (this.allSelected) {
      this.optionsList.deselectAll();
    } else {
      this.optionsList.selectAll();
    }

    this.allSelected = !this.allSelected;
  }

  // Handle selection confirmation
  onOkClick(): void {
    this.dialogRef.close(this.selectedOptions);
  }

  // Close the modal without commiting to a selection change
  onCancelClick(): void {
    this.dialogRef.close();
  }
}
