import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Api } from 'src/app/configs/config';
import { ActionPlan } from 'src/app/shared/models/actionPlan';


@Injectable({
  providedIn: 'root'
})
export class ActionPlanService {

  constructor(private http: HttpClient) { }
  addComment(c): Observable<any>{
    return this.http.post(Api.postActionPlanCommentUrl,c);
  }
  getActionPlanComments(planId: number): Observable<any>{
    return this.http.get(Api.getActionPlanCommentUrl.replace('{actionPlanId}',planId.toString()));
  }
  deleteActionPlanComment(id: number): Observable<any>{
    return this.http.delete<ActionPlan>(Api.deleteActionPlanCommentUrl.replace('{id}',id.toString()));
  }

  deleteActionPlan(id: number): Observable<any>{
    return this.http.delete<ActionPlan>(Api.deleteActionPlanUrl.replace('{id}',id.toString()));
  }

  getSiteActionPlans(nidSiteId: number): Observable<ActionPlan[]>{
    return this.http.get<ActionPlan[]>(Api.getSiteActionPlansUrl.replace('{id}',nidSiteId.toString()));
  }

  getActionPlan(id: number): Observable<ActionPlan>{
    return this.http.get<ActionPlan>(Api.getActionPlanUrl.replace('{id}',id.toString()));
  }

  createActionPlan(actionPlan: ActionPlan): Observable<any>{
    return this.http.post<ActionPlan>(Api.postActionPlanUrl,actionPlan);
  }

  updateActionPlan(id: number, actionPlan: ActionPlan): Observable<any>{
    return this.http.put<ActionPlan>(Api.putActionPlanUrl.replace('{id}',id.toString()),actionPlan);
  }
}
